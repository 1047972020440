import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { theme } from "../../constants/Theme";

const ToolTipHtml = ({ header = "", text }) => {
    return (
        <div style={{padding: '3px 1px'}}>
            <p style={{ fontSize: 14, fontWeight: 600, color: theme.color.black, marginBottom: 4 }}>{header}</p>
            <p style={{ color: theme.color.app_blue2, fontSize: 12, fontWeight: 500, textAlign: 'left' }}>{text}</p>
        </div>
    );
};
const AppToolTip = ({ header, text, children }) => {
    return (
        <Tooltip
            html={ToolTipHtml({ header, text })}
            interactive={true}
            position="top"
            trigger="mouseenter"
            arrow={true}
            theme="light">
            {children}
        </Tooltip>
    );
};

export default AppToolTip;
