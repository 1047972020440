import React from "react";
import styled from "styled-components";
import { Text } from "../../styled/typography";
interface Types {
    icon?: any;
    description: string;
    onClick?: any;
    noSideBorder?: boolean;
    style?: any;
    textStyle?: any;
}
export const AppButtonBorderWithIcon = ({ icon, description, onClick, noSideBorder = false, style, textStyle }: Types) => {
    return (
        <Wrap style={{ ...style }} onClick={onClick} noSideBorder={noSideBorder}>
            <CustomText style={{...textStyle}}>{description}</CustomText>
            {icon}
        </Wrap>
    );
};

const CustomText = styled(Text)`
    font-weight: 700;
    color: ${({ theme }) => theme.color.app_blue2};
`;
const Wrap = styled.div<{ noSideBorder: boolean }>`
    box-sizing: border-box;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${({ theme }) => theme.color.app_blue};
    border-radius: 2px;
    border-left-color: ${(props) => props.noSideBorder && "transparent"};
    border-right-color: ${(props) => props.noSideBorder && "transparent"};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;
