import { ZoomInOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getUserDataAction, updateHosesListAction } from "../../actions/account.action";
import {
    removeHoseByIdAction,
    removeHoseFromGuestCartAction,
    setCartItemActivityAction,
    setCartItemQuantityAction,
    setGuestCartItemActivityAction,
    setGuestCartItemQuantityAction,
} from "../../actions/addHoseToCart.action";
import { theme } from "../../constants/Theme";
import { RootState } from "../../reducers";
import { SubHeader } from "../../styled/typography";
import { getQuantityForSingleHoseFromStorage, updateHoseQuantityInStorage } from "../../utils/cart/HoseQuantityStorage";
import { extractSymbolsFromHosesList } from "../../utils/updateHosesList.utils";
import { AppButtonText } from "../buttons/App_ButtonText";
import { AdminExtraView } from "../cart/CartListItem/AdminExtraView";
import { CartListItemActions } from "../cart/CartListItem/CartListItemActions";
import { MainParameters } from "../cart/CartListItem/MainParameters";
import { HoseImage } from "../hoseImages/HoseImage";

export const ListItemCart = ({ data, hosesList, index }: any) => {
    const dispatch = useDispatch();
    let location = useLocation();
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const { leftFittingDetails, rightFittingDetails, totalPrice, sleeveDetails, hoseDetails, coverDetails } = data;
    const initialQuantity = getQuantityForSingleHoseFromStorage(data?.ID);
    const [quantity, setQuantity] = useState<number | string>(initialQuantity || 1);
    const removeHoseFromCart = async () => {
        if (isLogged) {
            if (window.confirm("Czy na pewno chcesz usunąć przewód z koszyka?")) {
                const handleRemoveAndUpdate = async () => {
                    await dispatch(removeHoseByIdAction({ clientId: data.clientId, hoseId: data.ID }));
                    await dispatch(getUserDataAction(data.clientId));
                    const symbolsString = extractSymbolsFromHosesList(hosesList);
                    await dispatch(updateHosesListAction(symbolsString));
                };
                handleRemoveAndUpdate();
            }
        } else {
            if (window.confirm("Czy na pewno chcesz usunąć przewód z koszyka?")) {
                const storeHoses = window.localStorage.getItem("hoses");
                const hoses = typeof storeHoses === "string" ? JSON.parse(storeHoses) : null;
                const updatedHoses = hoses?.filter((item: any) => {
                    return Number(item.id) !== Number(data.ID);
                });
                await window.localStorage.setItem("hoses", JSON.stringify(updatedHoses));
                dispatch(removeHoseFromGuestCartAction(data.ID)); //todo czy toj est dobrze??
                return await dispatch(removeHoseByIdAction({ clientId: "0", hoseId: data.ID }));
            }
        }
    };
    const changeItemActivity = (status: boolean, hoseId: string) => {
        if (isLogged) {
            return dispatch(setCartItemActivityAction(status, hoseId));
        } else {
            return dispatch(setGuestCartItemActivityAction(status, hoseId));
        }
    };
    const changeItemQuantity = (quantity: number | string, hoseId: string) => {
        updateHoseQuantityInStorage(hoseId, quantity);
        setQuantity(quantity);

        if (isLogged) {
            return dispatch(setCartItemQuantityAction(Number(quantity), hoseId));
        } else {
            return dispatch(setGuestCartItemQuantityAction(Number(quantity), hoseId));
        }
    };
    useEffect(() => {
        const quantityFromStorage = getQuantityForSingleHoseFromStorage(data?.ID);
        if (quantityFromStorage !== null && quantityFromStorage !== data.quantity) {
            setQuantity(quantityFromStorage);
            changeItemQuantity(quantityFromStorage, data?.ID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isSmallScreen = window.innerWidth < 585; // Sprawdza szerokość ekranu

    const stylesForImage = isSmallScreen ? { width: "48%", height: 80 } : { width: 130, height: 100 };
    const Title =
        index +
        ". Przewód " +
        data?.length +
        "cm DN-" +
        data?.hoseDetails?.srednica_wewnetrzna +
        " | " +
        data?.leftFittingDetails?.system +
        " - " +
        data?.rightFittingDetails?.system;

    return (
        <Wrapper
            style={{
                backgroundColor: !data.activity ? theme.color.app_grey4 : "white",
                borderColor: data.activity ? theme.color.app_blue2 : theme.color.app_grey6,
            }}>
            <TopWrap>
                <TopDescription>{Title}</TopDescription>
                {data?.isReordered === "reordered" && <TopQrCode>{data?.qrCode}</TopQrCode>}
            </TopWrap>
            <Content>
                <ImageAndActionsWrap>
                    <ImageAndDetailsWrap
                        to={`/Modal/HoseDetails`}
                        state={{ backgroundLocation: location, hoseDetailsData: data }}>
                        <HoseImage
                            leftFitting={{
                                angle: leftFittingDetails?.kat_zakucia,
                                type: leftFittingDetails?.typ_zakucia,
                            }}
                            rightFitting={{
                                angle: rightFittingDetails?.kat_zakucia,
                                type: rightFittingDetails?.typ_zakucia,
                            }}
                            styleWrapper={{
                                width: "auto",
                                height: "auto",
                                border: "none",
                                margin: 0,
                                padding: 0,
                            }}
                            styleImage={stylesForImage}
                        />

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <ZoomInOutlined style={{ color: theme.color.app_blue2, marginRight: 5 }} />
                            <AppButtonText
                                style={{ color: theme.color.app_blue2 }}
                                title={"Pokaz szczegóły"}
                                onClick={undefined}
                            />
                        </div>
                    </ImageAndDetailsWrap>

                    <CartListItemActions
                        data={data}
                        changeItemActivity={changeItemActivity}
                        quantity={quantity}
                        changeItemQuantity={changeItemQuantity}
                        totalPrice={totalPrice}
                        removeHoseFromCart={removeHoseFromCart}
                    />
                </ImageAndActionsWrap>
                <MainParameters data={data} coverDetails={coverDetails} />
            </Content>

            {/* <CartProductAvailability item={data} /> */}
            {user?.userData?.id === "72" && (
                <AdminExtraView
                    sleeveDetails={sleeveDetails}
                    leftFittingDetails={leftFittingDetails}
                    rightFittingDetails={rightFittingDetails}
                    hoseDetails={hoseDetails}
                />
            )}
        </Wrapper>
    );
};

const ImageAndDetailsWrap = styled(Link)`
    height: 100%;
    align-items: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;
const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: stretch;
`;
const ImageAndActionsWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 12px 12px 12px 0;
    @media (max-width: 585px) {
        padding: 6px 0px 6px 6px;
    }
`;
const TopWrap = styled(SubHeader)`
    box-sizing: border-box;
    padding: 12px 12px 10px 12px;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_blue2};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 585px) {
        padding: 8px 6px;
    }
`;
const TopDescription = styled(SubHeader)`
    font-weight: 600;
    @media (max-width: 585px) {
        font-size: 12px;
    }
`;
const TopQrCode = styled(SubHeader)`
    color: ${({ theme }) => theme.color.app_grey2};
    font-style: italic;
    @media (max-width: 585px) {
        font-size: 12px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid;
    border-radius: 5px;
`;
