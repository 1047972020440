import { ZoomInOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addHoseWithSameQRToCartAction } from "../../actions/addHoseToCart.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { SubHeader } from "../../styled/typography";
import { AppButtonText } from "../buttons/App_ButtonText";
import { AdminExtraView } from "../cart/CartListItem/AdminExtraView";
import { MainParameters } from "../cart/CartListItem/MainParameters";
import { HoseImage } from "../hoseImages/HoseImage";
import { OrderHoseDetailsAction } from "../orders/OrderHoseDetailsAction";

export const ListItemOrderHoses = ({ data, index }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const { leftFittingDetails, rightFittingDetails, sleeveDetails, hoseDetails, coverDetails } = data;

    const isSmallScreen = window.innerWidth < 585; // Sprawdza szerokość ekranu
    const stylesForImage = isSmallScreen ? { width: "48%", height: 80 } : { width: 130, height: 100 };
    const Title =
        index +
        ". Przewód " +
        data?.length +
        "cm DN-" +
        data?.hoseDetails?.srednica_wewnetrzna +
        " | " +
        data?.leftFittingDetails?.system +
        " - " +
        data?.rightFittingDetails?.system;
    const orderSameHose = () => {
        dispatch(
            addHoseWithSameQRToCartAction({
                hoseId: data?.qrCode,
                userId: isLogged ? user?.userData?.id : 0,
            })
        )
            .then((r: any) => {
                if (!isLogged) {
                    addHoseIdToLocalStorage(r.action.payload.data);
                }
                navigate("/Cart");
            })
            .catch(() => alert(" Ups, coś poszło nie tak. Spróbuj ponownie."));
    };
    return (
        <Wrapper
            style={{
                backgroundColor: theme.color.white,
                borderColor: theme.color.app_blue2,
            }}>
            <TopWrap>
                <TopDescription>{Title}</TopDescription>
                {data?.isReordered === "reordered" && <TopQrCode>{data?.qrCode}</TopQrCode>}
            </TopWrap>
            <Content>
                <ImageAndActionsWrap>
                    <ImageAndDetailsWrap
                        to={`/Modal/HoseDetails`}
                        state={{ backgroundLocation: location, hoseDetailsData: data, showOrderbutton: true }}>
                        <HoseImage
                            leftFitting={{
                                angle: leftFittingDetails?.kat_zakucia,
                                type: leftFittingDetails?.typ_zakucia,
                            }}
                            rightFitting={{
                                angle: rightFittingDetails?.kat_zakucia,
                                type: rightFittingDetails?.typ_zakucia,
                            }}
                            styleWrapper={{
                                width: "auto",
                                height: "auto",
                                border: "none",
                                margin: 0,
                                padding: 0,
                            }}
                            styleImage={stylesForImage}
                        />

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <ZoomInOutlined style={{ color: theme.color.app_blue2, marginRight: 5 }} />
                            <AppButtonText
                                style={{ color: theme.color.app_blue2 }}
                                title={"Pokaz szczegóły"}
                                onClick={undefined}
                            />
                        </div>
                    </ImageAndDetailsWrap>
                    <OrderHoseDetailsAction hoseData={data} orderSameHose={orderSameHose} />
                </ImageAndActionsWrap>

                <MainParameters data={{ ...data, activity: true }} coverDetails={coverDetails} />
            </Content>
            {user?.userData?.id === "72" && (
                <AdminExtraView
                    sleeveDetails={sleeveDetails}
                    leftFittingDetails={leftFittingDetails}
                    rightFittingDetails={rightFittingDetails}
                    hoseDetails={hoseDetails}
                />
            )}
        </Wrapper>
    );
};

const ImageAndDetailsWrap = styled(Link)`
    height: 100%;
    align-items: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;
const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: stretch;
`;
const ImageAndActionsWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 12px 12px 12px 0;
    @media (max-width: 585px) {
        padding: 6px 0px 6px 6px;
    }
`;
const TopWrap = styled(SubHeader)`
    box-sizing: border-box;
    padding: 12px 12px 10px 12px;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_blue2};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 585px) {
        padding: 8px 6px;
    }
`;
const TopDescription = styled(SubHeader)`
    font-weight: 600;
    @media (max-width: 585px) {
        font-size: 12px;
    }
`;
const TopQrCode = styled(SubHeader)`
    color: ${({ theme }) => theme.color.app_grey2};
    font-style: italic;
    @media (max-width: 585px) {
        font-size: 12px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid;
    border-radius: 5px;
`;
function addHoseIdToLocalStorage(data: any) {
    throw new Error("Function not implemented.");
}
