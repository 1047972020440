import { useState } from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { TitleHeader } from "../../styled/typography";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";

export const DetailsCollapsible = ({ title, children, initialState = false }) => {
    const [collapseOpen, setCollapseOpen] = useState(initialState);

    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 3,
                marginBottom: 3,
                paddingBottom: 0,
                backgroundColor: "white",
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <TitleAndImageWrap>
                            <Collapsible
                                open={initialState}
                                onOpen={() => setCollapseOpen(true)}
                                onClose={() => setCollapseOpen(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}>
                                        <TitleHeader>{title}</TitleHeader>
                                        <IconWrap
                                            style={{
                                                transform: !collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg />
                                        </IconWrap>
                                    </div>
                                }>
                                {children}
                            </Collapsible>
                        </TitleAndImageWrap>
                    ),
                },
            ]}
        />
    );
};

const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
