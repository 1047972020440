import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addHoseToCartAction } from "../actions/addHoseToCart.action";
import {
    clearCurrentCoverParams,
    setCurrentHoseAdditionalLabelText,
    setCurrentHoseAdditionalNotes,
    setCurrentHoseLengthAction,
    setCurrentHoseOrientationAction,
} from "../actions/currentHose.action";
import { setProgressAction } from "../actions/progress.action";
import { fetchSleeveAction } from "../actions/sleeve.action";
import { AdditionalLabels } from "../components/additionalData/AdditionalLabels";
import { AdditionalNotes } from "../components/additionalData/AdditionalNotes";
import FittingsPosition from "../components/additionalData/FittingsPosition";
import { HoseCoverHOC } from "../components/additionalData/HoseCoverHOC";
import HoseLength from "../components/additionalData/HoseLength";
import { AppButtonRegular } from "../components/buttons/App_ButtonRegular";
import { CartPriceResumeWrap } from "../components/cart/CartPriceResumeWrap";
import ListWrapper from "../components/listWrappers/ListWrapper";
import { useAppDispatch } from "../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../reducers";
import { addHoseToCartServiceTypes } from "../services/addHoseToCart.service";
import { TitleHeader } from "../styled/typography";
import { RouteWrapper } from "../styled/wrappers";
import { coverObjectStringify } from "../utils/coverObjectStringify";
import { countSingleHosePrice } from "../utils/price/countSingleHosePrice";

const AdditionalDataRoute = () => {
    const currentHose = useSelector((state: any) => state.currentHose);
    const sleeve = useSelector((state: RootState) => state.sleeve.sleeve);
    const { coverData, coverLength, coverPercentageLength, coverPosition } = useSelector(
        (state: RootState) => state.currentHose.cover
    );
    const userData = useSelector((state: RootState) => state.account.user.userData);
    const isLogged = useSelector((state: RootState) => state.account.isLogged);
    const [error, setError] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentEndingsPosition = useSelector((state: RootState) => state.currentHose.hose.orientation);
    const setCurrentEndingsPosition = (e) => dispatch(setCurrentHoseOrientationAction(e));
    const [additionalLabel, setAdditionalLabel] = useState(false);
    const [additionalNote, setAdditionalNote] = useState(false);

    useEffect(() => {
        dispatch(setProgressAction("additions", "right"));
        dispatch(fetchSleeveAction(currentHose.hose.parameters.symbol, currentHose.leftFitting.parameters.symbol));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hoseData: addHoseToCartServiceTypes = {
        hoseSymbol: currentHose.hose.parameters.symbol,
        leftFittingSymbol: currentHose.leftFitting.parameters.symbol,
        rightFittingSymbol: currentHose.rightFitting.parameters.symbol,
        sleeveSymbol: sleeve?.symbol,
        length: currentHose.hose.length,
        ending: currentHose.hose.orientation,
        clientId: isLogged ? userData?.id : 0,
        cover: coverPercentageLength
            ? coverObjectStringify({
                  symbol: coverData?.Symbol,
                  length: coverLength,
                  percentageLength: coverPercentageLength,
                  position: coverPosition?.value || "LEFT",
              })
            : null,
        additionalLabel: additionalLabel ? currentHose.additionalData.additionalLabelText : null,
        additionalNotes: additionalNote
            ? JSON.stringify({
                  createdAt: currentHose.additionalData.additionalNotes.createdAt,
                  description: currentHose.additionalData.additionalNotes.description,
              })
            : null,
    };
    const currentHosePrice = countSingleHosePrice({
        length: currentHose?.hose.length,
        hosePrice: currentHose?.hose.parameters.cena,
        leftFittingPrice: currentHose?.leftFitting?.parameters?.cena,
        rightFittingPrice: currentHose?.rightFitting?.parameters?.cena,
        sleevePrice: sleeve?.cena,
        coverPrice: coverData?.cena,
        coverLength: coverLength,
    });
    const addHoseIdToLocalStorage = (hoseId: string) => {
        let hosesIdList: string[] = [];
        //@ts-ignore
        hosesIdList = JSON.parse(window.localStorage.getItem("hoses")) || [];
        hosesIdList.push(hoseId);
        window.localStorage.setItem("hoses", JSON.stringify(hosesIdList));
    };
    const addHoseToCart = () => {
        setError("");
        if (currentHose?.hose?.length < 20) {
            setError("Minimalna długość przewodu to 20 centymetrów");
            return setTimeout(() => setError(""), 3000);
        }

        dispatch(addHoseToCartAction(hoseData))
            //@ts-ignore
            .then((r: any) => {
                if (!isLogged) {
                    addHoseIdToLocalStorage(r.action.payload.data);
                }
                dispatch(clearCurrentCoverParams());
                navigate("/Cart");
            })
            .catch(() => setError("Ups, coś poszło nie tak. Spróbuj ponownie."));
    };

    return (
        <RouteWrapper>
            <ListWrapper
                showSummary={false}
                title={"Pozostałe parametry"}
                list={
                    <Wrapper>
                        <HoseLength
                            leftFitting={currentHose?.leftFitting}
                            rightFitting={currentHose?.rightFitting}
                            length={currentHose?.hose?.length}
                            onLengthChange={(e) => dispatch(setCurrentHoseLengthAction(e))}
                        />
                        <FittingsPosition
                            currentPosition={currentEndingsPosition}
                            setCurrentPosition={setCurrentEndingsPosition}
                            leftFittingAngle={currentHose.leftFitting.angle}
                            leftFittingType={currentHose.leftFitting.type}
                            rightFittingAngle={currentHose.rightFitting.angle}
                            rightFittingType={currentHose.rightFitting.type}
                        />
                        {currentHose?.hose?.length >= 20 && (
                            <>
                                <div style={{ height: 30 }} />
                                <TitleHeader style={{ fontSize: 18 }}>Dodatki</TitleHeader>
                                <HoseCoverHOC />
                                <AdditionalLabels
                                    additionalLabelText={currentHose?.additionalData?.additionalLabelText}
                                    setAdditionalLabelText={(e) => dispatch(setCurrentHoseAdditionalLabelText(e))}
                                    setAdditionalLabel={setAdditionalLabel}
                                    additionalLabel={additionalLabel}
                                />
                                <AdditionalNotes
                                    additionalNoteText={currentHose?.additionalData?.additionalNotes?.description}
                                    setAdditionalNoteText={(e) =>
                                        dispatch(
                                            setCurrentHoseAdditionalNotes({
                                                description: e,
                                                createdAt: new Date().toLocaleDateString(),
                                            })
                                        )
                                    }
                                    setAdditionalNote={setAdditionalNote}
                                    additionalNote={additionalNote}
                                />
                            </>
                        )}

                        {currentHose?.hose.length >= 20 && (
                            <div style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
                                <CartPriceResumeWrap price={currentHosePrice} description="Cena przewodu" />
                            </div>
                        )}
                        {error?.length > 1 && <p style={{ color: "red", fontSize: 14, fontWeight: 400 }}>{error}</p>}
                        <AppButtonRegular onClick={addHoseToCart} title="Dodaj do koszyka" />
                    </Wrapper>
                }
            />
        </RouteWrapper>
    );
};

export default AdditionalDataRoute;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;
