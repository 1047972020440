import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { SubHeader, Text } from "../../styled/typography";
import { Button } from "../buttons/Button";

const coverPositions = [
    { name: "Przy lewym zakuciu", value: "LEFT" },
    { name: "Na środku", value: "CENTER" },
    { name: "Przy prawym zakuciu", value: "RIGHT" },
];
const coverLengths = [0.25, 0.5, 0.75, 1];

export const HoseCoverProperties = ({
    hoseLength,
    coverLength,
    coverPosition,
    setCoverLength,
    setPositionOfCover,
}: any) => {
    
    return (
        <Wrapper>
            <SubHeader>Długość i położenie osłony</SubHeader>
            <Text style={{ marginTop: 5, marginBottom: 10 }}>
                Osłona moze być na całym wężu - od zakucia do zakucia - lub na jego części. Wybierz jak długa w stosunku
                do węża ma być osłona.
            </Text>
            <ButtonsWrap>
                {coverLengths.map((percentage, index) => (
                    <SingleBtn key={index}>
                        <Button
                            bgColor={
                                coverLength / hoseLength >= percentage
                                    ? theme.color.app_blue2
                                    : theme.color.app_lightBlue
                            }
                            color={
                                coverLength / hoseLength >= percentage
                                    ? theme.color.app_lightBlue
                                    : theme.color.app_blue2
                            }
                            textStyles={
                                coverLength / hoseLength >= percentage ? { fontWeight: 700 } : { fontWeight: 500 }
                            }
                            title={(percentage * 100).toString() + " %"}
                            onClick={() => setCoverLength(percentage)}
                            isButtonGroup
                        />
                    </SingleBtn>
                ))}
            </ButtonsWrap>

            {Number(coverLength) !== Number(hoseLength) && (
                <>
                    <Text>Po której stronie znajdować się ma osłona? </Text>
                    <ButtonsWrap>
                        {coverPositions.map((item, index) => (
                            <SingleBtn key={index}>
                                <Button
                                    bgColor={
                                        coverPosition?.value === item?.value || coverPosition === item?.value
                                            ? theme.color.app_blue2
                                            : theme.color.app_lightBlue
                                    }
                                    color={
                                        coverPosition?.value === item?.value || coverPosition === item?.value
                                            ? theme.color.app_lightBlue
                                            : theme.color.app_blue2
                                    }
                                    title={item.name}
                                    onClick={() => setPositionOfCover(item)}
                                    isButtonGroup
                                    textStyles={
                                        coverPosition?.value === item?.value ? { fontWeight: 700 } : { fontWeight: 500 }
                                    }
                                />
                            </SingleBtn>
                        ))}
                    </ButtonsWrap>
                </>
            )}
        </Wrapper>
    );
};

const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
`;
const SingleBtn = styled.div`
    width: 32%;
    padding-bottom: 5px;
    cursor: pointer;
`;
const Wrapper = styled.div`
    padding-top: 20px;
`;
