import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { convertPriceToDisplayFormat } from "../utils/price/convertPriceToDisplayFormat";
import { countSingleHosePrice } from "../utils/price/countSingleHosePrice";
import { setLeftPdfImage, setRightPdfImage } from "./SwitchPdfHoseImage";
import { LogoPdf } from "./base64/LogoPdf";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export const createAndOpenOfferPdf = ({ hoses, orderData, paymentType }: any) => {
    const docDefinition = generatePdfDocDefinition({ hoses: hoses, orderData: orderData, paymentType: paymentType });
    const uniqueName = new Date().getTime();
    pdfMake.createPdf(docDefinition).open({}, window.open("", uniqueName.toString()));
};
const generateHosesList = ({ hoses }: any) => {
    return hoses.map((hose: any) => {
        const hoseData = hose?.hose_details?.[0];
        const leftFittingDetails = hoseData?.leftFittingDetails;
        const rightFittingDetails = hoseData?.rightFittingDetails;
        const LeftPdfImage = setLeftPdfImage(leftFittingDetails);
        const RightPdfImage = setRightPdfImage(rightFittingDetails);
        const coverDetails = hoseData?.coverDetails;

        const hosePrice = countSingleHosePrice({
            length: hoseData?.length,
            hosePrice: hoseData?.hoseDetails?.cena,
            leftFittingPrice: leftFittingDetails?.cena,
            rightFittingPrice: rightFittingDetails?.cena,
            sleevePrice: hoseData?.sleeveDetails?.cena,
            coverPrice: coverDetails?.cena,
            coverLength: coverDetails?.length,
        });
        const singleHosePrice = convertPriceToDisplayFormat(hosePrice);
        const everyHosePrice = convertPriceToDisplayFormat(hosePrice * hose?.quantity);

        return {
            columns: [
                {
                    width: "auto",
                    layout: noBordersLayout,
                    table: {
                        widths: [95, 95],
                        body: [
                            [
                                {
                                    width: "auto",
                                    image: LeftPdfImage,
                                    fit: [95, 100],
                                    margin: [0, 0, 0, 0],
                                    padding: [0, 0, 0, 0],
                                },
                                {
                                    width: "auto",
                                    image: RightPdfImage,
                                    fit: [95, 100],
                                    margin: [0, 0, 0, 0],
                                    padding: [0, 0, 0, 0],
                                },
                            ],
                            [
                                { text: "Cena 1 sztuki brutto", style: "text" },
                                { text: singleHosePrice + " PLN", style: "value" },
                            ],
                            [
                                { text: "Ilość sztuk", style: "text" },
                                { text: hose?.quantity, style: "value" },
                            ],
                            [
                                { text: `Cena całości brutto`, style: "text" },
                                { text: everyHosePrice + " PLN", style: "value" },
                            ],
                            [
                                { text: "VAT", style: "text" },
                                { text: "23%", style: "value" },
                            ],
                        ],
                    },
                },
                {
                    width: "auto",
                    canvas: [{ type: "line", x1: 0, y1: 0, x2: 0, y2: 100, lineWidth: 0.5, lineColor: "#FFF" }],
                    margin: [5, 0, 5, 0],
                },
                {
                    width: "auto",
                    table: {
                        widths: [90, 240],
                        body: [
                            [
                                { text: "Przewód", style: "text" },
                                { text: hoseData?.hoseDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "Lewe zakucie", style: "text" },
                                { text: leftFittingDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "Prawe zakucie", style: "text" },
                                { text: rightFittingDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "Długość", style: "text" },
                                { text: hoseData?.length + " cm", style: "value" },
                            ],
                            [
                                { text: "Orientacja zakuć", style: "text" },
                                { text: hoseData?.ending + "°", style: "value" },
                            ],
                            [
                                { text: "Osłona", style: "text" },
                                {
                                    text: coverDetails?.nazwa
                                        ? coverDetails?.nazwa + " " + coverDetails?.length
                                        : "Brak",
                                    style: "value",
                                },
                            ],
                        ],
                    },
                },
            ],
            columnGap: 0,
            margin: [0, 4, 0, 8],
        };
    });
};
export const generatePdfDocDefinition = ({ hoses, orderData, paymentType }: any) => {
    const offerGeneratedDate = () => {
        const currentDate = new Date(orderData?.orderDate);
        return currentDate.toLocaleString();
    }
    const datePlus3Days = () => {
        const currentDate = new Date(orderData?.orderDate);  // Używamy orderDate
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 3);
        return futureDate.toLocaleDateString();
    };
    const invoiceData = JSON.parse(orderData?.invoiceData);
    const orderPrice = convertPriceToDisplayFormat(orderData?.price);
    const shippingPrice = convertPriceToDisplayFormat(orderData?.shipping);
    const displayDocumentType = paymentType === "offer" ? "Oferta" : "Faktura proforma";
    return {
        watermark: { text: "Zakuwanie24.pl", color: "blue", opacity: 0.05, bold: false, italics: false },
        pageMargins: [20, 50, 20, 50],
        header: {
            stack: [
                {
                    style: "header",
                    columns: [
                        {
                            image: LogoPdf,
                            fit: [160, 60],
                        },
                    ],
                },
            ],
            
        },

        content: [

            //tu dane niezmienne
            {
                columns: [
                    [
                        { text: "EWEREST Mirosław Jachym", style: "text" },
                        { text: "Sienkiewicza 42/8, 39-300 Mielec", style: "text" },
                        { text: "Tel.: +48175853023, NIP: 817-111-31-00", style: "text" },
                        { text: "Nest Bank S.A. -rachunek w złotówkach,", style: "text" },
                        { text: "25 1870 1045 2078 1078 4543 0001", style: "text" },
                    ],
                    [
                        { text: `Dokument wygenerowany automatycznie`, style: "text", alignment: "right" },
                        { text: `Data: ${offerGeneratedDate()}`, style: "text", alignment: "right" },
                    ],
                ],
            },
            {
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 0.5, color: "#0285FF" }],
                margin: [0, 5, 0, 5],
            },
            //tu dane kupujacy i sprzedajacy
            {
                columns: [
                    [
                        { text: "SPRZEDAWCA:", style: "headerText" },
                        { text: "EWEREST Mirosław Jachym", style: "text" },
                        { text: "Sienkiewicza 42/8", style: "text" },
                        { text: "39-300 Mielec", style: "text" },
                        { text: "NIP: 817-111-31-00", style: "text" },
                    ],
                    [
                        { text: "KLIENT:", style: "headerText", margin: [50, 0, 0, 0] },
                        { text: invoiceData?.companyName, style: "text", alignment: "right" },
                        { text: invoiceData?.address1, style: "text", alignment: "right" },
                        { text: invoiceData?.address2, style: "text", alignment: "right" },
                        { text: invoiceData?.nip, style: "text", alignment: "right" },
                    ],
                ],
            },
            {
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1, color: "#FFF" }],
                margin: [0, 5, 0, 5],
            },
            // nr zamówienia
            { text: displayDocumentType, bold: true, alignment: "center", fontSize: 18 },
            { text: `Nr zamówienia: ${orderData?.id}`, bold: true, alignment: "center" },

            {
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1, color: "#0285FF" }],
                margin: [0, 10, 0, 10],
            },
            //tu przewody
            ...generateHosesList({ hoses: hoses }),
    
            {
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
                margin: [0, 10, 0, 5],
            },
            //tu podsumowanie
            { text: `Podsumowanie`, bold: true, margin: [0, 10, 10, 5] },
            shippingPrice && {
                columns: [
                    {
                        width: "auto",
                        margin: [0, 0, 0, 4],

                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    { text: "Koszt dostawy", style: "text",  },
                                    { text: shippingPrice + " PLN", style: "value" },
                                ],
                            ],
                        },
                    },
                ],
            },
            {
                columns: [
                    {
                        width: "auto",
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    { text: "Do zapłaty brutto", style: "text", bold: true, fontSize: 12 },
                                    { text: orderPrice + " PLN", style: "text", bold: true, fontSize: 12 },
                                ],
                            ],
                            layout: "noBorders",
                        },
                    },
                ],
            },
            { text: `Prosimy o podanie numeru zamówienia przy dokonaniu płatności oraz w korespondencji dotyczącej zamówienia.`, margin: [0, 3, 0, 0], fontSize: 9 },
            { text: `Data ważności oferty: ${datePlus3Days()}`, margin: [0, 5, 0, 0], fontSize: 9 },
            { text: `Termin realizacji zamówienia: do 7 dni od zaksięgowania płatności`, fontSize: 9 },
        ],
        footer: {
            margin: [20, 0, 20, 80],
            stack: [
                {
                    canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                },
                { text: 'Zastrzegamy sobie prawo do wydłużenia terminu realizacji zamówienia z powodu niedostępności towaru, lub odmowy i zwrotu ewentualnych wpłat. W razie opóźnień, poinformujemy Państwa o możliwym terminie realizacji.', alignment: 'center', fontSize: 8, margin: [0, 10, 0, 0] },
            ]
        },
        styles: {
            header: { margin: [20, 20, 20, 20] },
            headerText: {
                fontSize: 12,
            },
            subheader: {
                fontSize: 12,
                margin: [0, 5, 0, 1],
            },
            text: {
                fontSize: 10,
                bold: false,
                margin: [1, 1, 1, 1],
            },
            value: {
                fontSize: 10,
                bold: true,
                margin: [1, 1, 1, 1],
            },
        },
    };
};

const noBordersLayout = {
    hLineWidth: function () {
        return 0;
    },
    vLineWidth: function () {
        return 0;
    },
    marginLeft: function () {
        return 0;
    },
    marginRight: function () {
        return 0;
    },
    paddingLeft: function () {
        return 0;
    },
    paddingRight: function () {
        return 0;
    },
    paddingTop: function () {
        return 0;
    },
    paddingBottom: function () {
        return 0;
    },
};
