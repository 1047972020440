import {
    ADD_HOSE_TO_CART,
    CHANGE_HOSES_USER_BY_ID,
    GET_HOSE_BY_ID,
    GUEST_CART_HOSES,
    REMOVE_HOSE_BY_ID,
    SET_CART_ITEM_ACTIVITY,
    SET_CART_ITEM_QUANTITY,
    SET_GUEST_CART_ITEM_ACTIVITY,
    SET_GUEST_CART_ITEM_QUANTITY,
    GUEST_CART_HOSE_REMOVE,
    CLEAR_GUEST_CART,
    ADD_HOSE_WITH_SAME_QR_TO_CART,
    EDITE_HOSE_IN_CART
} from "../constants/actionNames";
import {
    addHoseToCartService,
    addHoseToCartServiceTypes,
    addHoseWithSameQRToCartService,
    changeHosesUserByIdService,
    editHoseInCartService,
} from "../services/addHoseToCart.service";
import {
    getHoseByIdService,
    getHoseByIdTypes,
    removeHoseByIdService,
} from "./../services/addHoseToCart.service";

export const addHoseToCartAction = (props: addHoseToCartServiceTypes) => ({
    type: ADD_HOSE_TO_CART,
    payload: addHoseToCartService(props),
});
export const addHoseWithSameQRToCartAction = (props: any) => ({
    type: ADD_HOSE_WITH_SAME_QR_TO_CART,
    payload: addHoseWithSameQRToCartService(props),
});
export const getHoseByIdAction = (props: getHoseByIdTypes) => ({
    type: GET_HOSE_BY_ID,
    payload: getHoseByIdService(props),
});
export const removeHoseByIdAction = (props: any) => ({
    type: REMOVE_HOSE_BY_ID,
    payload: removeHoseByIdService(props),
});
export const setCartItemActivityAction = (state: boolean, hoseId: string) => ({
    type: SET_CART_ITEM_ACTIVITY,
    payload: { state: state, hoseId: hoseId },
});
export const setGuestCartItemActivityAction = (state: boolean, hoseId: string) => ({
    type: SET_GUEST_CART_ITEM_ACTIVITY,
    payload: { state: state, hoseId: hoseId },
});
export const setCartItemQuantityAction = (
    quantity: number | string,
    hoseId: string
) => ({
    type: SET_CART_ITEM_QUANTITY,
    payload: { quantity: quantity, hoseId: hoseId },
});
export const setGuestCartItemQuantityAction = (
    quantity: number | string,
    hoseId: string
) => ({
    type: SET_GUEST_CART_ITEM_QUANTITY,
    payload: { quantity: quantity, hoseId: hoseId },
});

export const changeHosesUserByIdAction = ({ userId, hoseId }: any) => ({
    type: CHANGE_HOSES_USER_BY_ID,
    payload: changeHosesUserByIdService({ userId: userId, hoseId: hoseId }),
});
export const addHoseToGuestCartAction = (hose: any) => ({
    type: GUEST_CART_HOSES,
    payload: hose,
});
export const removeHoseFromGuestCartAction = (hoseId: any) => ({
    type: GUEST_CART_HOSE_REMOVE,
    payload: hoseId,
});
export const editHoseInCartAction = (data: any) => ({
    type: EDITE_HOSE_IN_CART,
    payload: editHoseInCartService(data),
});
export const clearGuestCart = () => ({
    type: CLEAR_GUEST_CART,
});
