import { ContainerOutlined, FileTextOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getOrderByIdAction } from "../actions/orders.action";
import { AppButtonText } from "../components/buttons/App_ButtonText";
import { setStatusColor } from "../components/listElements/ListItem_Order";
import { ListItemOrderHoses } from "../components/listElements/ListItem_OrderHoses";
import { theme } from "../constants/Theme";
import { useAppDispatch } from "../hooks/useAppDispatch/useAppDispatch";
import { createAndOpenOfferPdf } from "../pdf/createAndOpenOfferPdf";
import { RootState } from "../reducers";
import { RouteWrapper } from "../styled/wrappers";
import { decryptOrderId } from "../utils/crypto/crypto";
import { switchOrderStatus } from "../utils/switchOrderStatus";

export const SingleOrderRoute = () => {
    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const cleanOrderId = orderId.split("_")[0];
    const decryptedOrderId = decryptOrderId(cleanOrderId);
    const { order, loading } = useSelector((state: RootState) => state.orders);
    const paymentType = order?.[0]?.payment;
    const hosesInOrder = order?.[2];

    useEffect(() => {
        dispatch(getOrderByIdAction(decryptedOrderId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayDocsOnPaymentType = () => {
        const trimmedInput = paymentType?.trim().toLowerCase();
        if (trimmedInput === "payu") {
            return null;
        } else {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20, marginBottom: 20 }}>
                        <ContainerOutlined style={{ color: theme.color.app_blue2, marginRight: 8, fontSize: 16 }} />
                        <AppButtonText
                            title={`Wyświetl fakturę proforma`}
                            style={{ fontSize: 14 }}
                            onClick={() =>
                                createAndOpenOfferPdf({
                                    hoses: hosesInOrder,
                                    orderData: order[0],
                                    paymentType: "proforma",
                                })
                            }
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20, marginBottom: 20 }}>
                        <FileTextOutlined style={{ color: theme.color.app_blue2, marginRight: 8, fontSize: 16 }} />
                        <AppButtonText
                            title={`Wyświetl ofertę`}
                            style={{ fontSize: 14 }}
                            onClick={() =>
                                createAndOpenOfferPdf({
                                    hoses: hosesInOrder,
                                    orderData: order[0],
                                    paymentType: "offer",
                                })
                            }
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <RouteWrapper>
            <Wrapper>
                <CartBody>
                    {!loading && hosesInOrder && order && (
                        <>
                            <Header>
                                <InboxOutlined style={{ fontSize: 28, marginRight: 10 }} />
                                Zamówienie 0000-{decryptedOrderId}
                            </Header>
                            <Line />
                            <ElementValue>Data zamówienia {order?.[0]?.orderDate}</ElementValue>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                                <ElementValue>Status zamówienia &nbsp;</ElementValue>
                                <Status color={order?.[0]?.orderStatus}>
                                    {switchOrderStatus(order?.[0]?.orderStatus)}
                                </Status>
                            </div>
                            {displayDocsOnPaymentType()}
                            <Line />
                            {hosesInOrder?.map((item: any, index: number) => (
                                <ListItemOrderHoses
                                    data={{ ...item?.hose_details?.[0], ...item }}
                                    key={index}
                                    index={index + 1}
                                />
                            ))}
                        </>
                    )}
                </CartBody>
            </Wrapper>
        </RouteWrapper>
    );
};

const Header = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin: 24px 0;
    display: flex;
    align-items: center;
`;
const CartBody = styled.div`
    border-radius: 15px;
    padding: 17px;
    background-color: ${({ theme }) => theme.color.white};
    @media (max-width: 1024px) {
        width: auto;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.white};
    box-sizing: border-box;
    min-height: 100vh;
    @media (min-width: 619px) {
        width: 612px;
    }
`;
const ElementValue = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.app_grey5};
    margin: 25px 0 15px 0;
`;
const Status = styled.p<{ color: any }>`
    font-size: 12px;
    font-weight: 600;
    color: ${({ color }) => setStatusColor(color)};
`;
