import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import AppToolTip from "../tooltips/AppToolTip";

const ListItemHoseDiameter = ({ data, isOpen }: any) => {
    const tooltip = (
        <AppToolTip
            header=""
            text={
                <p style={{ textAlign: "center" }}>
                    DN12 oraz DN13 to ten sam wymiar. Oznaczenie różni się zależnie od producenta.
                </p>
            }>
            <div
                style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                }}>
                <Text>12, 13</Text>
                <InfoIcon />
            </div>
        </AppToolTip>
    );

    const displayDN = data?.dn === 13 ? tooltip : <Text>{data?.dn}</Text>;

    return (
        <Wrapper
            style={{
                borderBottomLeftRadius: isOpen ? 0 : 5,
                borderBottomRightRadius: isOpen ? 0 : 5,
                borderBottom: isOpen ?? "none",
                borderColor: isOpen ? theme.color.app_blue2 : theme.color.app_grey6,
            }}>
            <TextsWrap>
                <TextContainer>{displayDN}</TextContainer>
                <TextContainer>
                    <Text>{data?.cale}</Text>
                </TextContainer>
                <TextContainer>
                    <Text>{data?.mm}</Text>
                </TextContainer>
            </TextsWrap>
            <IconWrap
                style={{
                    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
                    transitionProperty: "transform",
                    transitionDuration: "0.2s",
                }}>
                <IconArrowDownSvg />
            </IconWrap>
        </Wrapper>
    );
};

export default ListItemHoseDiameter;
const InfoIcon = styled(QuestionCircleOutlined)`
    font-size: 14px;
    color: ${({ theme }) => theme.color.black};
    margin-left: 3px;
    margin-bottom: 4px;
    @media (max-width: 585px) {
        font-size: 14px;
        margin-left: 1px;
        margin-top: -6px;
    }
`;
const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const Text = styled.p`
    margin: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
    text-align: center;
    font-weight: 500;
`;
const TextsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TextContainer = styled.div`
    width: 100%;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.color.app_grey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    height: 52px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
        border-color: ${({ theme }) => theme.color.app_blue2} !important;
    }
`;
