export const theme = {
    color: {
        transparent: "transparent",
        white: "#FFFFFF",
        black: "#000000",
        app_blue: "#2699FB",
        app_blue2: "#0285FF",
        app_lightBlue: "#E7F0FF",
        app_grey: "#E0E0E0",
        app_grey2: "#696969",
        app_grey3: "#7C7C7C",
        app_grey4: "#F2F2F2",
        app_grey5: "#E8E8E8",
        app_grey6: "#CDD4D9",
        app_grey7: "#A9A9A9",
        app_orange: "#FF8A1E",
        app_yellow: "#FFFF00",
        app_yellow2: "#FFFF0050",
        app_green: "#00BA34",
        app_lightGreen: "#ddffd9",
        app_red: "#DD343D",
        app_lightRed: "#FCA2A6",
    },
    fontSize: {
        font8: "8px",
        font9: "9px",
        font10: "10px",
        font11: "11px",
        font12: "12px",
        font13: "13px",
        font14: "14px",
        font15: "15px",
        font16: "16px",
        font17: "17px",
        font18: "18px",
        font20: "20px",
        font21: "21px",
        font23: "23px",
        font24: "24px",
        font25: "25px",
        font27: "27px",
        font28: "28px",
        font30: "30px",
    },
    fontFamily: {
        sansLight: "Sans_Light",
    },
    flex: {
        none: "none",
        one: 1,
    },
};
