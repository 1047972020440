import React from 'react';

export const AdminExtraView = ({sleeveDetails, leftFittingDetails, rightFittingDetails, hoseDetails}) => {
  return (
      <>
          <div style={{ fontSize: 12, fontWeight: "bold" }}>TULEJKA:</div>
          <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
              Symbol: {JSON.stringify(sleeveDetails?.symbol)} || Cena: {JSON.stringify(sleeveDetails?.cena)} || Stan
              Magazynowy: {JSON.stringify(sleeveDetails?.stan_magazynowy)}
          </div>
          <div style={{ fontSize: 12, fontWeight: "bold" }}>Lewe zakucie:</div>
          <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
              Symbol: {JSON.stringify(leftFittingDetails?.symbol)} || Cena: {JSON.stringify(leftFittingDetails?.cena)}{" "}
              || Stan Magazynowy: {JSON.stringify(leftFittingDetails?.stan_magazynowy)}
          </div>
          <div style={{ fontSize: 12, fontWeight: "bold" }}>Prawe zakucie:</div>
          <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
              Symbol: {JSON.stringify(rightFittingDetails?.symbol)} || Cena: {JSON.stringify(rightFittingDetails?.cena)}{" "}
              || Stan Magazynowy: {JSON.stringify(rightFittingDetails?.stan_magazynowy)}
          </div>
          <div style={{ fontSize: 12, fontWeight: "bold" }}>Przewód:</div>
          <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
              Symbol: {JSON.stringify(hoseDetails?.symbol)} || Cena: {JSON.stringify(hoseDetails?.cena)} || Stan
              Magazynowy: {JSON.stringify(hoseDetails?.stan_magazynowy)}
          </div>
      </>
  );
};