import { ShoppingCartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addHoseWithSameQRToCartAction } from "../../actions/addHoseToCart.action";
import { AppButtonRegular } from "../../components/buttons/App_ButtonRegular";
import { HoseImage } from "../../components/hoseImages/HoseImage";
import { DetailsCollapsible } from "../../components/summary/DetailsCollapsible";
import { SummaryElement } from "../../components/summary/SummaryElement";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { RootState } from "../../reducers";
import { Header } from "../../styled/typography";

export const HoseDetailsRoute = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const goBack = () => {
        navigate(-1);
    };
    const {
        hoseDetails,
        leftFittingDetails,
        rightFittingDetails,
        length,
        ending,
        coverDetails,
        additionalLabel,
        additionalNotes,
        qrCode,
    } = location?.state?.hoseDetailsData;
    const showOrderbutton = location?.state?.showOrderbutton;

    const addHoseIdToLocalStorage = (hose: { id: number; qrCode: string }) => {
        let hosesIdList: { id: string; qrCode: string }[] = [];
        hosesIdList = JSON.parse(window.localStorage.getItem("hoses") || "[]");

        // Przekonwertowanie `id` na string i dodanie do listy
        hosesIdList.push({ id: hose.id.toString(), qrCode: hose.qrCode });

        window.localStorage.setItem("hoses", JSON.stringify(hosesIdList));
    };
    const orderSameHose = () => {
        dispatch(
            addHoseWithSameQRToCartAction({
                hoseId: location?.state?.hoseDetailsData.qrCode,
                userId: isLogged ? user?.userData?.id : 0,
            })
        )
            .then((r: any) => {
                if (!isLogged) {
                    addHoseIdToLocalStorage(r.action.payload.data);
                }
                navigate("/Cart");
            })
            .catch(() => alert(" Ups, coś poszło nie tak. Spróbuj ponownie."));
    };
    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                <Header>Parametry przewodu {qrCode}</Header>
                <HoseImage
                    styleWrapper={{ width: "auto", height: "auto", border: "none", marginTop: 15, padding: 0 }}
                    leftFitting={{ angle: leftFittingDetails?.kat_zakucia, type: leftFittingDetails?.typ_zakucia }}
                    rightFitting={{ angle: rightFittingDetails?.kat_zakucia, type: rightFittingDetails?.typ_zakucia }}
                />
                {showOrderbutton && (
                    <AppButtonRegular
                        style={{ flexDirection: "row-reverse", margin: "5px 0 25px 0" }}
                        icon={
                            <ShoppingCartOutlined style={{ marginRight: 14, fontSize: 24, color: theme.color.white }} />
                        }
                        title="Dodaj taki sam przewód do koszyka"
                        onClick={orderSameHose}
                    />
                )}

                <InitialDataWrap>
                    <SummaryElement header="Długość" title={length + " cm"} />
                    <SummaryElement header="Orientacja zakończeń" title={ending + " stopni"} />
                </InitialDataWrap>
                <SummaryElement style={{ marginLeft: 25 }} header="Dodatkowa plakietka" title={additionalLabel} />
                <SummaryElement
                    style={{ marginLeft: 25 }}
                    header="Komentarz do przewodu"
                    title={additionalNotes?.description}
                />

                <DetailsCollapsible title={"Przewód"} initialState={true}>
                    <SummaryElement header="Średnica wewnętrzna" title={hoseDetails.srednica_wewnetrzna} />
                    <SummaryElement header="Oploty" title={hoseDetails.oploty} />
                    <SummaryElement header="Ciśnienie robocze" title={hoseDetails.cisnienie_robocze} />
                    <SummaryElement header="Ciśnienie rozrywania" title={hoseDetails.cisnienie_rozrywania} />
                    <SummaryElement header="Temp. pracy" title={hoseDetails.temperatura_pracy} />
                    <SummaryElement header="Średnica zewnętrzna" title={hoseDetails.srednica_zewnetrzna} />
                    <SummaryElement header="Promień gięcia" title={hoseDetails.promien_giecia} />
                    <SummaryElement header="Waga" title={hoseDetails.waga} />
                </DetailsCollapsible>
                <DetailsCollapsible title={"Lewe zakucie"}>
                    <SummaryElement header="Nazwa" title={leftFittingDetails.nazwa} />
                    <SummaryElement header="System" title={leftFittingDetails.system} />
                    <SummaryElement header="Typ zakucia" title={leftFittingDetails.typ_zakucia} />
                    <SummaryElement header="Kąt zakucia" title={leftFittingDetails.kat_zakucia} />
                    <SummaryElement header="Średnica gwintu" title={leftFittingDetails.srednica_gwintu} />
                </DetailsCollapsible>
                <DetailsCollapsible title={"Prawe zakucie"}>
                    <SummaryElement header="Nazwa" title={rightFittingDetails.nazwa} />
                    <SummaryElement header="System" title={rightFittingDetails.system} />
                    <SummaryElement header="Typ zakucia" title={rightFittingDetails.typ_zakucia} />
                    <SummaryElement header="Kąt zakucia" title={rightFittingDetails.kat_zakucia} />
                    <SummaryElement header="Średnica gwintu" title={rightFittingDetails.srednica_gwintu} />
                </DetailsCollapsible>
                {coverDetails && (
                    <DetailsCollapsible title={"Osłona przewodu"}>
                        <SummaryElement header="Nazwa" title={coverDetails?.nazwa} />
                        <SummaryElement header="Długość" title={coverDetails?.length + " cm"} />
                        <SummaryElement header="Położenie" title={coverDetails?.position} />
                    </DetailsCollapsible>
                )}
                <div style={{ height: 30 }} />
                {showOrderbutton && (
                    <AppButtonRegular
                        style={{ flexDirection: "row-reverse" }}
                        icon={
                            <ShoppingCartOutlined style={{ marginRight: 14, fontSize: 24, color: theme.color.white }} />
                        }
                        title="Dodaj taki sam przewód do koszyka"
                        onClick={orderSameHose}
                    />
                )}
                <div style={{ height: 15 }} />
                <AppButtonRegular
                    bgColor={theme.color.app_lightBlue}
                    color={theme.color.app_blue2}
                    borderColor={theme.color.app_blue2}
                    title="Zamknij"
                    onClick={() => goBack()}
                />
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};

const InitialDataWrap = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 25px;
`;
const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const ModalContentWrapper = styled.div`
    box-sizing: padding-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: 5%;
    height: auto;
    z-index: 2;
    background-color: white;
    border-radius: 5px;
    @media (min-width: 1000px) {
        width: 400px;
        margin-top: 50px;
        margin-bottom: 100px;
        height: auto;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: scroll;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 11;
`;
