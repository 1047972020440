import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setTempAddress } from "../../actions/account.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { InputAddress } from "../inputs/InputAddress";
import { TitleHeader } from "../../styled/typography";

export const CartAddressElement = ({validationError}) => {
    const dispatch = useAppDispatch();
    const { tempAddress, isLogged } = useSelector((state: RootState) => state.account);
    const { individualData, mail, phone } = useSelector((state: RootState) => state.account.user.userData);

    useEffect(() => {
        isLogged && dispatch(setTempAddress({ ...individualData, mail, phone })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: validationError ? theme.color.app_lightRed : theme.color.white,
                borderColor: validationError ? theme.color.app_red : theme.color.app_lightBlue,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <TitleAndImageWrap>
                            <TitleHeader style={{ marginBottom: 10 }}>Dane odbiorcy przesyłki</TitleHeader>
                            <InputAddress
                                value={tempAddress.fname}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, fname: e.target.value }))
                                }
                                title={"Imię"}
                                placeholder="np. Jan"
                            />
                            <InputAddress
                                wrapperStyles={{ marginTop: 8 }}
                                value={tempAddress.lname}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, lname: e.target.value }))
                                }
                                title={"Nazwisko"}
                                placeholder="np. Kowalski"
                            />
                            <InputAddress
                                wrapperStyles={{ marginTop: 8 }}
                                value={tempAddress.address1}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, address1: e.target.value }))
                                }
                                title={"Ulica i numer budynku"}
                                placeholder="np. ul. Słowackiego 1/1"
                            />
                            <InputAddress
                                wrapperStyles={{ marginTop: 8 }}
                                value={tempAddress.address2}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, address2: e.target.value }))
                                }
                                title={"Kod pocztowy i miejscowość"}
                                placeholder="np. 00-000 Warszawa"
                            />
                            <InputAddress
                                wrapperStyles={{ marginTop: 8 }}
                                value={tempAddress.phone}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, phone: e.target.value }))
                                }
                                title={"Numer telefonu"}
                                placeholder="np. 123 123 123"
                            />
                            <InputAddress
                                wrapperStyles={{ marginTop: 8 }}
                                value={tempAddress.mail}
                                onTextChange={(e: any) =>
                                    dispatch(setTempAddress({ ...tempAddress, mail: e.target.value }))
                                }
                                title={"Email"}
                                placeholder="np. email@email.pl"
                            />
                        </TitleAndImageWrap>
                    ),
                },
            ]}
        />
    );
};

const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
