import { useDispatch } from "react-redux";
import { addHoseToGuestCartAction, getHoseByIdAction } from "../../../actions/addHoseToCart.action";

interface HosesFromLSTypes {
    qrCode: string;
    id: string;
}
export const useGuestHosesToCart = () => {
    const dispatch = useDispatch();
    const removeFailedHoses = (array) => {
        return array.filter(
            (item) => item !== "Blad zapisu w bazie danych" && item !== "Nie udało się zaktualizować węża"
        );
    };
    const removeDuplicatesByQRCode = (hoses) => {
        const uniqueHoses = hoses.reduce((acc, hose) => {
            acc[hose.qrCode] = hose;  // Nadpisuje poprzednie węże o tym samym qrCode
            return acc;
        }, {});
        return Object.values(uniqueHoses);  // Zwraca tylko unikalne węże
    };

    const guestHosesToCart = async () => {
        const hoseDataFromStorage = await window.localStorage.getItem("hoses");
        if (!hoseDataFromStorage) return false;
        if (hoseDataFromStorage === "undefined") return false;
        const hoses = await JSON.parse(hoseDataFromStorage);
        const hosesFromStorage = removeFailedHoses(hoses);
        const hosesWithoutDuplicates = removeDuplicatesByQRCode(hosesFromStorage);
        await window.localStorage.setItem("hoses", JSON.stringify(hosesWithoutDuplicates));

        if (hosesWithoutDuplicates.length === 0) return;
        hosesWithoutDuplicates.map((hose: HosesFromLSTypes) => {
            const qrCode: string = hose.qrCode;
            //@ts-ignore
            return dispatch(getHoseByIdAction({ qrCode })).then((r: any) => {
                if (r.action.type === "GET_HOSE_BY_ID_FULFILLED") {
                    //@ts-ignore
                    return dispatch(addHoseToGuestCartAction(r.action.payload.data));
                }
            });
        });
    };
    return {
        guestHosesToCart: () => guestHosesToCart(),
    };
};
