import { useState } from "react";
import Collapsible from "react-collapsible";
import { Checkbox } from "react-input-checkbox";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { InputAddress } from "../inputs/InputAddress";

export const AdditionalNotes = ({ additionalNote, setAdditionalNote, setAdditionalNoteText, additionalNoteText }) => {
    const [collapseOpen, setCollapseOpen] = useState(false);

    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 4,
                marginBottom: 4,
                paddingBottom: 0,
                backgroundColor: theme.color.white,
                borderColor: collapseOpen ? theme.color.app_blue2 : theme.color.app_lightBlue,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <TitleAndImageWrap>
                            <Collapsible
                                onOpen={() => setCollapseOpen(true)}
                                onClose={() => setCollapseOpen(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}>
                                        <SubHeader>+ Dodaj komentarz do przewodu</SubHeader>
                                        <IconWrap
                                            style={{
                                                transform: collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg />
                                        </IconWrap>
                                    </div>
                                }>
                                <Wrapper>
                                    <Text style={{ marginTop: 24, marginBottom: 10 }}>
                                        Komentarz będzie widoczny w koszyku, zamówieniu, oraz po zeskanowaniu kodu.
                                    </Text>
                                    <CheckBoxWrapper
                                        style={{
                                            borderColor: !additionalNote && theme.color.app_blue2,
                                            backgroundColor: !additionalNote && theme.color.app_lightBlue,
                                        }}>
                                        <Checkbox
                                            modification="hoseAdditionalCheckbox"
                                            theme="fancy-checkbox"
                                            disabled={false}
                                            value={!additionalNote}
                                            onChange={() => setAdditionalNote(false)}>
                                            <Text style={{ marginLeft: 10 }}>Brak komentarza</Text>
                                        </Checkbox>
                                    </CheckBoxWrapper>
                                    <CheckBoxWrapper
                                        style={{
                                            borderColor: additionalNote && theme.color.app_blue2,
                                            backgroundColor: additionalNote && theme.color.app_lightBlue,
                                        }}>
                                        <Checkbox
                                            modification="hoseAdditionalCheckbox"
                                            theme="fancy-checkbox"
                                            disabled={false}
                                            value={additionalNote}
                                            onChange={() => setAdditionalNote(true)}>
                                            <Text style={{ marginLeft: 10 }}>Komentarz do przewodu</Text>
                                        </Checkbox>
                                        <InputAddress
                                            maxLength={500}
                                            disabled={!additionalNote}
                                            wrapperStyles={{ marginTop: 8, marginLeft: 20, width: "calc(100% - 30px)" }}
                                            inputStyle={{
                                                borderColor: additionalNote && theme.color.app_blue2,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                padding: 10,
                                            }}
                                            value={additionalNoteText}
                                            onTextChange={(e) => setAdditionalNoteText(e.target.value)}
                                            title={""}
                                            placeholder="Wpisz komentarz do przewodu"
                                        />
                                    </CheckBoxWrapper>
                                </Wrapper>
                            </Collapsible>
                        </TitleAndImageWrap>
                    ),
                },
            ]}
        />
    );
};

const CheckBoxWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 4px;
    border: 1px solid ${({ theme }) => theme.color.app_grey};
    border-radius: 5px;
    width: 100%;
    padding: 10px 12px;
    cursor: pointer;
`;
export const Text = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const Wrapper = styled.div`
    width: 100%;
`;
///
const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const SubHeader = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
