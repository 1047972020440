import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { theme } from "../../../constants/Theme";
import { useRemoveHosesFromLocalStore } from "../../../hooks/localStoreHosesMaintenance/useRemoveHosesFromLocalStore.ts/useRemoveHosesFromLocalStore";
import { useAppDispatch } from "../../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../../reducers";
import { ErrorText, Header } from "../../../styled/typography";
import { OrderDataTypes, paymentTypes } from "../../../types/orderDataTypes";
import { encryptOrderId } from "../../../utils/crypto/crypto";
import { createInternalOrder } from "../../../utils/payment and order/createInternalOrder";
import { prepareOrderData } from "../../../utils/payment and order/prepareOrderData";
import { validateCartAddress } from "../../../utils/payment and order/validation/validateCartAddress";
import { AppButtonRegular } from "../../buttons/App_ButtonRegular";
import { ReusableInfoElementWithEmoji } from "../../elements/ReusableInfoElementWithEmoji";
import { CartPriceResumeWrap } from "../CartPriceResumeWrap";

type Types = {
    cartTotalPrice: number;
    shippingIncluded: boolean | any;
    modalClose: any;
};

export const CartProformaConfirm = ({ cartTotalPrice, shippingIncluded, modalClose }: Types) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const uniqueName = new Date().getTime();

    const { guestCartHoses } = useSelector((state: RootState) => state.guestCart);
    const { removeHosesFromLocalStore } = useRemoveHosesFromLocalStore();
    const { tempAddress, invoiceData, courierInfo, comment, isLogged } = useSelector(
        (state: RootState) => state.account
    );
    const { updatedCartHoses, hosesIds, userData } = useSelector((state: RootState) => state.account.user);

    const [loading, setLoading] = useState(false);
    const [adressError, setAddressError] = useState("");
    const [paymentType, setPaymentType] = useState<paymentTypes>("proforma");
    const [error, setError] = useState("");

    const orderData: OrderDataTypes = prepareOrderData({
        shippingIncluded: shippingIncluded,
        tempAddress: tempAddress,
        hosesList: isLogged ? updatedCartHoses : guestCartHoses,
        userData: userData,
        courierInfo: courierInfo,
        comment: comment,
        cartTotalPrice: cartTotalPrice,
        invoiceData: invoiceData,
        isLogged: isLogged,
        payment: paymentType,
    });

    const handleSubmit = async (paymentType: paymentTypes) => {
        setPaymentType(paymentType);
        setLoading(true);
        try {
            const isValid = await validateCartAddress(tempAddress);
            if (isValid !== true) {
                setAddressError(isValid);
                alert("Podaj adres odbioru przesyłki!");
                return setTimeout(() => setAddressError(""), 5000);
            }
            const orderId = await createInternalOrder(
                dispatch,
                hosesIds,
                { ...orderData, payment: paymentType },
                setError,
                isLogged
            );
            const orderIdEncrypted = encryptOrderId(orderId);

            navigate(`/order-completed/${orderIdEncrypted}_${uniqueName}/${uniqueName}`);

            if (!isLogged) {
                removeHosesFromLocalStore(JSON.parse(orderData?.hosesIds));
            }
        } catch (err) {
            console.error(`Wystąpił błąd: ${err}`);
            alert(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Header>Zamów i wygeneruj Proformę / Ofertę</Header>
            <ReusableInfoElementWithEmoji
                style={{ backgroundColor: "white", marginTop: 20 }}
                infoRowStyle={{ alignItems: "center" }}
                infos={[
                    {
                        emoji: "📄",
                        description: "Dokument będzie możliwy do pobrania w następnym kroku",
                    },
                    {
                        emoji: "✉️",
                        description: "Na podany adres email zostanie wysłany link do dokumentu",
                    },
                ]}
            />
            <CartPriceResumeWrap price={cartTotalPrice} description="Suma zamówienia" />
            <BtnWrap style={{ backgroundColor: "white" }}>
                <ErrorText>{adressError}</ErrorText>
                <ErrorText style={{ marginBottom: error ? 10 : 0 }}>{error}</ErrorText>

                {loading && (
                    <div style={{ width: "100%", height: 40, justifyContent: "center", display: "flex" }}>
                        <MoonLoader color={"black"} loading={loading} size={30} speedMultiplier={0.4} />
                    </div>
                )}
                <div style={{ height: 20 }} />
                <AppButtonRegular
                    disable={loading}
                    onClick={() => handleSubmit("proforma")}
                    title={"Zamów i otrzymaj ofertę / proformę"}
                    style={{
                        marginBottom: 25,
                    }}
                />
                <AppButtonRegular
                    onClick={modalClose}
                    borderColor={theme.color.app_blue2}
                    bgColor={theme.color.white}
                    color={theme.color.app_blue}
                    title="Anuluj"
                />
            </BtnWrap>
        </>
    );
};

const BtnWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding: 16px 0;
`;
