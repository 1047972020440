import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useDispatch } from "react-redux";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import "./App.css";
import { AppHelmet } from "./AppHelmet";
import { getUserDataAction } from "./actions/account.action";
import { Footer } from "./components/footer/Footer";
import AppHeader from "./components/headers/AppHeader";
import { HelpContact } from "./components/help/HelpContact";
import { Intro } from "./components/intro/Intro";
import BurgerMenu from "./components/menu/BurgerMenu";
import SummaryDesktop from "./components/summary/SummaryDesktop";
import { theme } from "./constants/Theme";
import usePageViews from "./hooks/usePageView/usePageView";
import AdditionalDataRoute from "./routes/AdditionalDataRoute";
import { CartRoute } from "./routes/CartRoute";
import FittingAngleRoute from "./routes/FittingAngleRoute/FittingAngleRoute";
import FittingDiameterRoute from "./routes/FittingDiameterRoute";
import FittingTypeRoute from "./routes/FittingTypeRoute/FittingTypeRoute";
import HoseDiameterRoute from "./routes/HoseDiameterRoute";
import HoseTypeRoute from "./routes/HoseTypeRoute";
import { EditHoseInCartRoute } from "./routes/ModalRoutes/EditHoseInCartRoute";
import { HoseDetailsRoute } from "./routes/ModalRoutes/HoseDetailsRoute";
import { LogInModalRoute } from "./routes/ModalRoutes/LogInModalRoute";
import { ProfileModalRoute } from "./routes/ModalRoutes/ProfileModalRoute";
import { RegisterModalRoute } from "./routes/ModalRoutes/RegisterModalRoute";
import { OrderCreatedWithoutPaymentRoute } from "./routes/OrderCreatedWithoutPaymentRoute";
import { OrdersRoute } from "./routes/OrdersRoute";
import { PaymentCompletedRoute } from "./routes/PaymentCompletedRoute";
import { SingleOrderRoute } from "./routes/SingleOrderRoute";
import { PrivacyPolicyRoute } from "./routes/TermsAndConditionsRoutes/PrivacyPolicyRoute";
import { RegulaminRoute } from "./routes/TermsAndConditionsRoutes/RegulaminRoute";
import store from "./store/store";
import ScrollToTop from "./utils/ScrollToTop";
import { setApiToken, setAuthorizationHeader, setDefaultBaseUrl } from "./utils/auth.utils";
import { setupAxiosInterceptors } from "./utils/axiosInterceptor";
// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
    usePageViews();
    setupAxiosInterceptors(store.dispatch);
    const screenSize = useScreenSize();
    let location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    let state = location.state as { backgroundLocation?: Location };

    const fetchUserData = async (userId: string | number) => {
        await dispatch(getUserDataAction(userId));
    };

    const initializeApp = async () => {
        setApiToken();
        setDefaultBaseUrl();
        const url = window.location.href;
        if (url.includes("/Cart") && (url.includes("accessToken") || url.includes("refreshToken"))) {
            const urlParams = new URLSearchParams(window.location.search);
            const encodedAccessToken = urlParams.get("accessToken");
            const encodedRefreshToken = urlParams.get("refreshToken");
            const userId = urlParams.get("userId");
            if (encodedAccessToken && encodedRefreshToken && userId) {
                const accessToken = decodeURIComponent(encodedAccessToken);
                const refreshToken = decodeURIComponent(encodedRefreshToken);
                setAuthorizationHeader(accessToken);
                await window.localStorage.setItem("accessToken", accessToken);
                await window.localStorage.setItem("refreshToken", refreshToken);
                await window.localStorage.setItem("userData", JSON.stringify(userId));
                // navigate("/Cart");
                setTimeout(() => {
                    dispatch(getUserDataAction(userId));
                }, 500);
                // window.location.reload();
            }
            return;
        } else {
            const storedUserId = await window.localStorage.getItem("userData");
            const storedAccessToken = await window.localStorage.getItem("accessToken");
            if (storedAccessToken) {
                setAuthorizationHeader(storedAccessToken);
                const logInDataParsed = JSON.parse(storedUserId);
                await fetchUserData(logInDataParsed);
            }
        }
    };

    useEffect(() => {
        initializeApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (window.location.href.includes("PaymentCompleted")) {
            navigate("/PaymentCompleted");
        }
        if (window.location.href.includes("order-completed")) {
            const urlParts = window.location.href.split("/");
            const orderId = urlParts[urlParts.length - 2];
            const randomString = urlParts[urlParts.length - 1];
            navigate(`/order-completed/${orderId}/${randomString}`);
        }
        if (window.location.href.includes("single-order")) {
            const urlParts = window.location.href.split("/");
            const orderId = urlParts[urlParts.length - 1];
            navigate(`/single-order/${orderId}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const handleBrowserBackButton = () => {
            navigate(-1);
        };
        window.addEventListener("popstate", handleBrowserBackButton);
        return () => window.removeEventListener("popstate", handleBrowserBackButton);
    });
    useEffect(() => {
        window.history.pushState({}, "", `${location.pathname}`);
    }, [location]);
    const displaySummary = () => {
        if (screenSize.width >= 1023 && location.pathname.includes("create")) {
            return <SummaryDesktop />;
        } else if (screenSize.width >= 1023 && location.pathname === "/") {
            return <Intro />;
        }
    };

    return (
        <Main>
            <AppHelmet />
            <ScrollToTop />
            {/* <IntroModal /> */}

            <AppHeader isMenuOpen={isMenuOpen} setMenuOpen={() => setMenuOpen(!isMenuOpen)} />

            <ContentWrapper>
                <Routes location={state?.backgroundLocation || location}>
                    <Route path="/" element={<HoseTypeRoute />} />
                    <Route path="create/hoseDiameter" element={<HoseDiameterRoute />} />
                    <Route path="create/fittingType" element={<FittingTypeRoute />} />
                    <Route path="create/fittingAngle" element={<FittingAngleRoute />} />
                    <Route path="create/fittingDiameter" element={<FittingDiameterRoute />} />
                    <Route path="create/additionalData" element={<AdditionalDataRoute />} />
                    <Route path="Cart" element={<CartRoute />} />
                    <Route path="Orders" element={<OrdersRoute />} />
                    <Route path="single-order/:orderId" element={<SingleOrderRoute />} />
                    <Route path="PaymentCompleted" element={<PaymentCompletedRoute />} />
                    <Route
                        path="order-completed/:orderId/:randomString"
                        element={<OrderCreatedWithoutPaymentRoute />}
                    />
                    <Route path="PrivacyPolicy" element={<PrivacyPolicyRoute />} />
                    <Route path="Regulamin" element={<RegulaminRoute />} />
                </Routes>
                {state?.backgroundLocation && (
                    <Routes>
                        <Route path="/Modal/LogIn" element={<LogInModalRoute />} />
                        <Route path="/Modal/Register" element={<RegisterModalRoute />} />
                        <Route path="/Modal/Profile" element={<ProfileModalRoute />} />
                        <Route path="/Modal/HoseDetails" element={<HoseDetailsRoute />} />
                        <Route path="/Modal/EditHoseInCart" element={<EditHoseInCartRoute />} />
                    </Routes>
                )}
                {displaySummary()}
            </ContentWrapper>
            <BurgerMenu setMenuOpen={(e: boolean) => setMenuOpen(e)} isMenuOpen={isMenuOpen} />
            <Footer />
            {screenSize.width <= 1023 && <HelpContact />}
            <CookieConsent
                location="bottom"
                buttonText="Akceptuj"
                onAccept={undefined}
                hideOnAccept={true}
                buttonStyle={{
                    backgroundColor: theme.color.app_blue2,
                    color: "white",
                    fontWeight: 700,
                    borderRadius: 3,
                    marginTop: 3,
                    marginBottom: 3,
                }}
                style={{
                    backgroundColor: theme.color.app_lightBlue,
                }}
                contentStyle={{
                    fontWeight: 500,
                    fontSize: 12,
                    color: theme.color.app_blue2,
                }}>
                Strona korzysta z plików cookie w celu realizacji usług zgodnie z{" "}
                <Link style={{ color: theme.color.app_blue2, textDecoration: "none" }} to="/PrivacyPolicy">
                    Polityką Cookies
                </Link>
                . Możesz określić warunki przechowywania lub dostępu mechanizmu cookie w Twojej przeglądarce.
            </CookieConsent>
            {/* <WhatsApp/> */}
            {/* <TawkMessengerReact propertyId="649a912794cf5d49dc600e35" widgetId="1h3tse0sh" /> */}
        </Main>
    );
}

export default App;

const Main = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    padding-top: 48px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.app_grey4};
`;
