import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { getUserDataAction } from "../../actions/account.action";
import {
    addHoseToCartAction,
    clearGuestCart,
    editHoseInCartAction,
    removeHoseByIdAction,
    removeHoseFromGuestCartAction,
} from "../../actions/addHoseToCart.action";
import { fetchCoversAction } from "../../actions/cover.action";
import { AdditionalLabels } from "../../components/additionalData/AdditionalLabels";
import { AdditionalNotes } from "../../components/additionalData/AdditionalNotes";
import FittingsPosition from "../../components/additionalData/FittingsPosition";
import { HoseCover } from "../../components/additionalData/HoseCover";
import { HoseCoverProperties } from "../../components/additionalData/HoseCoverProperties";
import HoseLength from "../../components/additionalData/HoseLength";
import { AppButtonRegular } from "../../components/buttons/App_ButtonRegular";
import { CartPriceResumeWrap } from "../../components/cart/CartPriceResumeWrap";
import { ReusableInfoElementWithEmoji } from "../../components/elements/ReusableInfoElementWithEmoji";
import { theme } from "../../constants/Theme";
import { useGuestHosesToCart } from "../../hooks/localStoreHosesMaintenance/useGetHosesFromLocalStore/useGetHosesFromLocalStore";
import { useRemoveHosesFromLocalStore } from "../../hooks/localStoreHosesMaintenance/useRemoveHosesFromLocalStore.ts/useRemoveHosesFromLocalStore";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { RootState } from "../../reducers";
import { Header, SubHeader, TitleHeader } from "../../styled/typography";
import { coverObjectStringify } from "../../utils/coverObjectStringify";
import { countSingleHosePrice } from "../../utils/price/countSingleHosePrice";

export const EditHoseInCartRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { guestHosesToCart } = useGuestHosesToCart();
    const { removeHosesFromLocalStore } = useRemoveHosesFromLocalStore();
    const addHoseIdToLocalStorage = async (hoseId: string) => {
        let hosesIdList: string[] = [];
        //@ts-ignore
        hosesIdList = (await JSON.parse(window.localStorage.getItem("hoses"))) || [];
        hosesIdList.push(hoseId);
        await window.localStorage.setItem("hoses", JSON.stringify(hosesIdList));
    };
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const currentHose = location.state.hoseDetailsData;
    const { ID, qrCode, length, ending, additionalNotes, additionalLabel, leftFittingDetails, rightFittingDetails } =
        location.state.hoseDetailsData;
    const coversList = useSelector((state: RootState) => state.cover.covers);
    const [loading, setLoading] = useState(false);
    const goBack = () => {
        navigate(-1);
    };
    const [editedLength, setEditedLength] = useState(length);
    const [editedEnding, setEditedEnding] = useState(ending);
    const [includeAdditionalNote, setIncludeAdditionalNote] = useState(!!additionalNotes?.description);
    const [editedAdditionalNotes, setEditedAdditionalNotes] = useState(additionalNotes?.description);

    const [isLabelOnHose, setIsLabelOnHose] = useState(!!additionalLabel);
    const [editedAdditionalLabel, setEditedAdditionalLabel] = useState(additionalLabel);

    const [coverDetailsObject, setCoverDetailsObject] = useState(currentHose.coverDetails);
    const [editedCoverDetails, setEditedCoverDetails] = useState({
        length: currentHose?.coverDetails?.length || editedLength,
        percentageLength: currentHose.coverDetails?.percentageLength || 1,
        position: currentHose.coverDetails?.position || "LEFT",
        symbol: currentHose.coverDetails?.symbol || null,
    });
    const [isAnyCoverPicked, setIsAnyCoverPicked] = useState(!!currentHose.coverDetails?.length);

    const saveChanges = async () => {
        const updatedFields: any = {};

        if (editedLength !== length) {
            updatedFields.length = editedLength;
        }

        if (editedEnding !== ending) {
            updatedFields.ending = editedEnding;
        }
        //TODO: Sprawdzenie, czy jest to new hose czy z qrkodu
        //Jeśli z QR kodu to dodawanie nowej notatki do tablicy
        //Jeśli z listy to edycja notatki
        updatedFields.additionalNotes = {
            createdAt: new Date().toLocaleDateString(),
            description: editedAdditionalNotes,
        };

        updatedFields.additionalLabel = editedAdditionalLabel;

        if (
            currentHose.coverDetails?.length !== editedCoverDetails?.length ||
            currentHose.coverDetails?.symbol !== editedCoverDetails?.symbol ||
            currentHose.coverDetails?.position !== editedCoverDetails?.position
        ) {
            if (coverDetailsObject?.length === null) {
                
            } else {
                updatedFields.coverDetails = coverObjectStringify(editedCoverDetails);
            }
        }
        if (Object.keys(updatedFields).length > 0) {
            try {
                setLoading(true);
                if ("length" in updatedFields || "ending" in updatedFields) {
                    if (currentHose?.isReordered !== "new") {
                        await dispatch(removeHoseByIdAction({ clientId: currentHose.clientId, hoseId: ID }));
                        const newHose = {
                            additionalLabel: editedAdditionalLabel ?? null,
                            additionalNotes: editedAdditionalNotes ? JSON.stringify(editedAdditionalNotes) : null,
                            clientId: currentHose.clientId,
                            cover: currentHose?.coverDetails?.length ? coverObjectStringify(editedCoverDetails) : null,
                            ending: editedEnding,
                            hoseSymbol: currentHose.hoseDetails.symbol,
                            leftFittingSymbol: currentHose.leftFittingDetails.symbol,
                            length: editedLength,
                            rightFittingSymbol: currentHose.rightFittingDetails.symbol,
                            sleeveSymbol: currentHose.sleeveDetails.symbol,
                        };
                        try {
                            const result: any = await dispatch(addHoseToCartAction(newHose));

                            if (!isLogged) {
                                await addHoseIdToLocalStorage(result.action.payload.data);
                                await removeHosesFromLocalStore([{ id: Number(ID), qrCode: qrCode }]);
                                await dispatch(removeHoseFromGuestCartAction(ID)); //todo czy to jest dobrze??
                                await dispatch(removeHoseByIdAction({ clientId: "0", hoseId: ID }));
                            }
                        } catch (error) {
                            console.error("Error adding hose to cart:", error);
                        }
                    } else {
                        dispatch(editHoseInCartAction({ id: ID, ...updatedFields }));
                    }
                } else {
                    dispatch(editHoseInCartAction({ id: ID, ...updatedFields }));
                }
            } catch (e) {
                console.error(e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                    if (isLogged) {
                        dispatch(getUserDataAction(user?.userData.id));
                    } else {
                        dispatch(clearGuestCart());
                        guestHosesToCart();
                    }
                    goBack();
                }, 2000);
            }
        } else {
            navigate(-1);
        }
    };
    const currentHosePrice = countSingleHosePrice({
        length: editedLength,
        hosePrice: currentHose?.hoseDetails?.cena,
        leftFittingPrice: currentHose?.leftFittingDetails?.cena,
        rightFittingPrice: currentHose?.rightFittingDetails?.cena,
        sleevePrice: currentHose?.sleeveDetails?.cena,
        coverPrice: coverDetailsObject?.cena,
        coverLength: editedCoverDetails?.length,
    });
    ///covers
    useEffect(() => dispatch(fetchCoversAction(currentHose.hoseDetails.symbol)), []); // eslint-disable-line react-hooks/exhaustive-deps
    const [collapseOpen, setCollapseOpen] = useState(true);
    const clearCover = () => {
        setCoverDetailsObject(null);
        setEditedCoverDetails({ length: null, percentageLength: null, position: null, symbol: null });
        setIsAnyCoverPicked(false);
    };
    const setCurrentCover = (selectedCover) => {
        let coverLength = editedCoverDetails?.length ?? editedLength;
        let percentageLength = editedCoverDetails?.percentageLength ?? coverDetailsObject?.percentageLength ?? 1;

        setCoverDetailsObject(selectedCover);
        setEditedCoverDetails({
            ...editedCoverDetails,
            symbol: selectedCover?.Symbol,
            length: parseInt(coverLength),
            percentageLength: percentageLength,
        });
        setIsAnyCoverPicked(true);
    };
    const setCoverLength = (percentage) => {
        setEditedCoverDetails({
            ...editedCoverDetails,
            percentageLength: percentage,
            length: Math.ceil(editedLength * percentage),
        });
    };
    useEffect(() => {
        setCoverLength(editedCoverDetails?.percentageLength);
    }, [editedLength]); // eslint-disable-line react-hooks/exhaustive-deps

    const clearAdditionalLabel = (e) => {
        setIsLabelOnHose(e);
        setEditedAdditionalLabel("");
    };
    const clearAdditionalNote = (e) => {
        setIncludeAdditionalNote(e);
        setEditedAdditionalNotes("");
    };
    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>

                <Header style={{ marginBottom: 30 }}>
                    <EditOutlined style={{ marginRight: 5, fontSize: 22 }} />
                    Edycja przewodu
                </Header>
                <HoseLength
                    leftFitting={{ angle: leftFittingDetails?.kat_zakucia, type: leftFittingDetails?.typ_zakucia }}
                    rightFitting={{ angle: rightFittingDetails?.kat_zakucia, type: rightFittingDetails?.typ_zakucia }}
                    length={editedLength}
                    onLengthChange={(e) => setEditedLength(e)}
                    isEdited={currentHose?.isReordered !== "new"}
                />
                <FittingsPosition
                    currentPosition={editedEnding}
                    setCurrentPosition={setEditedEnding}
                    leftFittingAngle={leftFittingDetails?.kat_zakucia}
                    leftFittingType={leftFittingDetails?.typ_zakucia}
                    rightFittingAngle={rightFittingDetails?.kat_zakucia}
                    rightFittingType={rightFittingDetails?.typ_zakucia}
                    isEdited={currentHose?.isReordered !== "new"}
                />

                <div style={{ height: 30 }} />
                <TitleHeader style={{ fontSize: 18 }}>Dodatki</TitleHeader>

                <AdditionalLabels
                    additionalLabelText={editedAdditionalLabel}
                    setAdditionalLabelText={(e) => setEditedAdditionalLabel(e)}
                    setAdditionalLabel={(e) => clearAdditionalLabel(e)}
                    additionalLabel={isLabelOnHose}
                />
                <AdditionalNotes
                    additionalNoteText={editedAdditionalNotes}
                    setAdditionalNoteText={(e) => setEditedAdditionalNotes(e)}
                    setAdditionalNote={(e) => clearAdditionalNote(e)}
                    additionalNote={includeAdditionalNote}
                />
                <ReusableInfoElementWithEmoji
                    style={{
                        marginTop: 10,
                        marginBottom: -2,
                        paddingBottom: 0,
                        backgroundColor: theme.color.white,
                        borderColor: collapseOpen ? theme.color.app_blue2 : theme.color.app_lightBlue,
                    }}
                    infos={[
                        {
                            emoji: "",
                            description: (
                                <TitleAndImageWrap>
                                    <Collapsible
                                        open={true}
                                        onOpen={() => setCollapseOpen(true)}
                                        onClose={() => setCollapseOpen(false)}
                                        trigger={
                                            <div
                                                style={{
                                                    flexDirection: "row",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                }}>
                                                <SubHeader>+ Dodaj osłonę przewodu</SubHeader>
                                                <IconWrap
                                                    style={{
                                                        transform: collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                        transitionProperty: "transform",
                                                        transitionDuration: "0.2s",
                                                    }}>
                                                    <IconArrowDownSvg />
                                                </IconWrap>
                                            </div>
                                        }>
                                        <div>
                                            <HoseCover
                                                coversList={coversList}
                                                coverData={editedCoverDetails}
                                                setCurrentCover={(item) => setCurrentCover(item)}
                                                clearCover={clearCover}
                                                coverPosition={editedCoverDetails?.position}
                                                isAnyCoverPicked={isAnyCoverPicked}
                                            />
                                            {editedCoverDetails?.symbol && (
                                                <HoseCoverProperties
                                                    hoseLength={editedLength}
                                                    coverLength={editedCoverDetails?.length}
                                                    coverPosition={editedCoverDetails?.position}
                                                    setCoverLength={(e) => setCoverLength(e)}
                                                    setPositionOfCover={(e) =>
                                                        setEditedCoverDetails({
                                                            ...editedCoverDetails,
                                                            position: e?.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Collapsible>
                                </TitleAndImageWrap>
                            ),
                        },
                    ]}
                />
                <div style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
                    <CartPriceResumeWrap price={currentHosePrice} description="Cena przewodu" />
                </div>

                {loading && (
                    <div style={{ width: "100%", height: 30, justifyContent: "center", display: "flex" }}>
                        <MoonLoader color={"black"} loading={loading} size={15} speedMultiplier={0.4} />
                    </div>
                )}
                <AppButtonRegular disable={editedLength < 20} onClick={saveChanges} title="Zapisz zmiany" />
                <div style={{ height: 10 }} />
                <AppButtonRegular
                    color={theme.color.app_blue2}
                    bgColor={theme.color.app_lightBlue}
                    borderColor={theme.color.app_blue}
                    onClick={() => navigate(-1)}
                    title="Anuluj"
                />
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};
const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    height: auto;
    margin: 5%;
    z-index: 2;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.app_blue2};
    @media (min-width: 515px) {
        width: 500px;
        margin-top: 50px;
        margin-bottom: 100px;
        height: auto;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: scroll;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 11;
`;
