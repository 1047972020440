import styled from "styled-components";
import IconCloseSvg from "../../../icons/Icon_CloseSvg";
import { CartConfirm } from "./CartConfirm";
import { CartProformaConfirm } from "./CartProformaConfirm";

export const CartConfirmModal = ({
    visibility,
    setVisibility,
    cartTotalPrice,
    shippingIncluded,
    paymentType,
    setPaymentType,
}: any) => {
    // const { isLogged } = useSelector((state: RootState) => state.account);

    // const { updatedCartHoses } = useSelector((state: RootState) => state.account.user);
    // const { guestCartHoses } = useSelector((state: RootState) => state.guestCart);
    // const exceededItems = useExceededItems(isLogged ? updatedCartHoses : guestCartHoses);
    // console.log('exceededItems', exceededItems)
    // const [itemsAreExceeded, setItemsAreExceeded] = useState<boolean>(false);
    // useEffect(() => {
    //     if (exceededItems.length > 0) {
    //         return setItemsAreExceeded(true);
    //     } else {
    //         return setItemsAreExceeded(false);
    //     }
    // }, [visibility, exceededItems]);
    const modalClose = () => {
        setVisibility(false);
        setPaymentType(null);
    };
    const displayPaymentType = () => {
        if (paymentType === "proforma") {
            return (
                <CartProformaConfirm
                    cartTotalPrice={cartTotalPrice}
                    shippingIncluded={shippingIncluded}
                    modalClose={modalClose}
                />
            );
        } else if (paymentType === "payu") {
            return (
                <CartConfirm
                    cartTotalPrice={cartTotalPrice}
                    shippingIncluded={shippingIncluded}
                    modalClose={modalClose}
                />
            );
        }
    };
    return (
        <ModalOpacity onClick={modalClose} style={{ display: visibility ? "flex" : "none" }}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <CloseBtnWrap>
                    <CloseBtn onClick={modalClose}>
                        <IconCloseSvg />
                    </CloseBtn>
                </CloseBtnWrap>
                {/* {itemsAreExceeded ? (
                    <ExceededItemsInfo
                        setItemsAreExceeded={() => setItemsAreExceeded(false)}
                        exceededItems={exceededItems}
                        modalClose={modalClose}
                    />
                ) : ( */}
                {displayPaymentType()}
                {/* )} */}
            </Wrapper>
        </ModalOpacity>
    );
};

const CloseBtnWrap = styled.div`
    height: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const CloseBtn = styled.div`
    cursor: pointer;
`;
const Wrapper = styled.div`
    height: auto;
    width: 70%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 36px;
    @media (min-width: 1000px) {
        margin-top: 120px;
        height: auto;
        width: 500px;
    }
`;
const ModalOpacity = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000030;
    backdrop-filter: blur(1.2px);
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
    z-index: 2;
`;
