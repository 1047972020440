import React from 'react';
import { theme } from '../../constants/Theme';
import { TitleHeader, ErrorText } from '../../styled/typography';
import { AppButtonRegular } from '../buttons/App_ButtonRegular';
import { ReusableInfoElementWithEmoji } from '../elements/ReusableInfoElementWithEmoji';
import PAYU_LOGO_WHITE from "../../assets/PAYU_LOGO_WHITE.png";

export const CartPayOptionsElement = ({ addressValidationError, invoiceValidationError, isDisabled, continueToPayment }) => {
    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: theme.color.white,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <>
                            <TitleHeader>Opcje płatności</TitleHeader>
                            <div style={{ height: 10 }} />
                            {addressValidationError && (
                                <ErrorText style={{ padding: 10 }}>{addressValidationError}</ErrorText>
                            )}
                            {invoiceValidationError && (
                                <ErrorText style={{ padding: 10 }}>{invoiceValidationError}</ErrorText>
                            )}
                            <div style={{ height: 10 }} />
                            <AppButtonRegular
                                disable={isDisabled}
                                title="Zamawiam i płacę z "
                                onClick={() => continueToPayment("payu")}
                                style={{ marginBottom: 10, padding: 0, height: 40 }}
                                icon={
                                    <img
                                        style={{ height: 21, marginLeft: 5 }}
                                        src={PAYU_LOGO_WHITE}
                                        alt="lengthExample"
                                    />
                                }
                            />
                            <AppButtonRegular
                                disable={isDisabled}
                                title="Zamawiam i chcę ofertę lub proformę"
                                onClick={() => continueToPayment("proforma")}
                                style={{ marginBottom: 5 }}
                                color={isDisabled ? theme.color.white : theme.color.app_blue2}
                                bgColor={theme.color.app_lightBlue}
                                borderColor={isDisabled ? theme.color.white : theme.color.app_blue2}
                            />
                        </>
                    ),
                },
            ]}
        />
    );
};