import { object, string } from "yup";
export interface Address {
    address1: string;
    address2: string;
    lname: string;
    fname: string;
    phone: string;
    mail: string;
}

export const schemaSendAddress = object({
    address1: string()
        .required("Ulica i numer jest wymagany")
        .min(2, "Ulica i numer muszą zawierać co najmniej 2 znaki"),
    address2: string()
        .required("Kod pocztowy i miejscowość są wymagane")
        .min(2, "Kod pocztowy i miejscowość muszą zawierać co najmniej 2 znaki"),
    lname: string().required("Nazwisko jest wymagane").min(2, "Nazwisko musi zawierać co najmniej 2 znaki"),
    fname: string().required("Imię jest wymagane").min(2, "Imię musi zawierać co najmniej 2 znaki"),
    phone: string()
        .required("Numer telefonu jest wymagany")
        .min(7, "Numer telefonu musi zawierać co najmniej 7 znaków"),
    mail: string().required("Email jest wymagany").email("Nieprawidłowy adres e-mail"),
});

export const validateCartAddress = async (address: Address) => {
    try {
        const isValid = await schemaSendAddress.validate(address);
        if (isValid) {
            return true;
        }
    } catch (err: any) {
        return err.message;
    }
};

