import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { convertPriceToDisplayFormat } from "../../../utils/price/convertPriceToDisplayFormat";
import AppToolTip from "../../tooltips/AppToolTip";

export const CartListItemPriceWithModal = ({ data, totalPrice }) => {
    return (
        <BottomWrapElement>
            <AppToolTip
                header="Jak liczymy cenę?"
                text={
                    <p>
                        Na cenę składają się koszty: przewodu, zakuć, tulejek oraz usługi zakucia. Grubsze przewody z
                        większą ilością oplotów wymagają więcej pracy, a niektóre z nich dodatkowo specjalnej obróbki
                        zakończeń, co podnosi cenę.
                    </p>
                }>
                <div
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}>
                    <PriceLabel>Cena brutto</PriceLabel>
                    <InfoIcon />
                </div>
            </AppToolTip>

            <PriceValue>{data && convertPriceToDisplayFormat(totalPrice * data?.quantity)} zł</PriceValue>
        </BottomWrapElement>
    );
};
const InfoIcon = styled(QuestionCircleOutlined)`
    font-size: 13px;
    color: ${({ theme }) => theme.color.black};
    margin-left: 3px;
    margin-bottom: 4px;
    @media (max-width: 585px) {
        font-size: 12px;
        margin-left: 1px;
        margin-top: -6px;
    }
`;
const PriceLabel = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black};
    @media (max-width: 585px) {
        font-size: 12px;
        font-weight: 500;
    }
`;
const PriceValue = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
    @media (max-width: 585px) {
        font-size: 13px;
        font-weight: 700;
    }
`;
const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey5};
    @media (max-width: 585px) {
        padding: 0px 8px;
    }
`;
