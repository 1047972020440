import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { encryptOrderId } from "../../utils/crypto/crypto";
import { convertPriceToDisplayFormat } from "../../utils/price/convertPriceToDisplayFormat";
import { switchOrderStatus } from "../../utils/switchOrderStatus";
import { AppButtonText } from "../buttons/App_ButtonText";
import { OrderPayConfirmModal } from "../orders/OrderPayConfirmModal";

export const ListItemOrder = ({ data, ip }: any) => {
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);
    const { hoses, userData } = data;
    const navigate = useNavigate();
    const quantityArray = hoses.map((item: any) => item.quantity);
    const orderHosesQuantity = quantityArray.reduce((a: any, b: any) => a + b, 0);
    const cryptedOrderId = encryptOrderId(userData?.id);
    const todayDateAsNumber = Date.now();

    const openOrderDetails = () => {
        navigate(`/single-order/${cryptedOrderId}_${todayDateAsNumber}`);
    };

    return (
        <Wrapper>
            <LeftWrap>
                <OrderWrap>
                    <OrderTitle>Numer zamówienia</OrderTitle>
                    <OrderNumber>0000-{userData?.id}</OrderNumber>
                </OrderWrap>
                <OrderWrap>
                    <ItemsCount style={{ marginBottom: 0 }}>{data?.userData?.comment}</ItemsCount>
                </OrderWrap>
                <OrderWrap>
                    <ItemsCount>{orderHosesQuantity} szt. przewodów</ItemsCount>
                    <AppButtonText onClick={openOrderDetails} title="Pokaz szczegóły" />
                </OrderWrap>
            </LeftWrap>
            <RightWrap>
                <div>
                    <OrderNumber>Razem z dostawą {convertPriceToDisplayFormat(userData?.price)} zł</OrderNumber>
                    <ItemsCount style={{ marginTop: 4, textAlign: "end" }}>{userData?.orderDate}</ItemsCount>
                </div>

                <StatusWrap>
                    {userData?.orderStatus === "incompleted" && (
                        <AppButtonText title={"Opłać teraz"} onClick={() => setModalVisibility(true)} />
                    )}
                    <Status color={userData?.orderStatus}>{switchOrderStatus(userData?.orderStatus)}</Status>
                </StatusWrap>
            </RightWrap>
            <OrderPayConfirmModal
                ip={ip}
                order={data}
                visibility={modalVisibility}
                setVisibility={() => setModalVisibility(!modalVisibility)}
            />
        </Wrapper>
    );
};
export const setStatusColor = (state: any) => colorByStatus[state] ?? theme.color.black;

const colorByStatus: any = {
    incompleted: theme.color.app_red,
    pending: theme.color.app_orange,
    in_progress: theme.color.app_blue2,
    finished: theme.color.app_green,
};

const Status = styled.p<{ color: any }>`
    font-size: 12px;
    font-weight: 400;
    color: ${({ color }) => setStatusColor(color)};
    margin-left: 5px;
`;
const StatusWrap = styled.div`
    display: flex;
    flex-direction: row;
`;
const OrderTitle = styled.p`
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1px;
`;
const OrderNumber = styled.p`
    font-size: 14px;
    font-weight: 700;
`;
const ItemsCount = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_grey2};
    margin: 13px 0;
`;
const OrderWrap = styled.div``;

const RightWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;
const LeftWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 17px 14px 17px 17px;
    border: 1px solid ${({ theme }) => theme.color.app_grey};
    border-radius: 5px;
`;
