import { OrderDataTypes, prepareOrderDataTypes } from "../../types/orderDataTypes";

export const prepareOrderData = ({
    shippingIncluded,
    tempAddress,
    hosesList,
    userData,
    courierInfo,
    comment,
    cartTotalPrice,
    invoiceData,
    isLogged,
    payment
}: prepareOrderDataTypes) => {
    const listOfHosesIdsWithQuantity = hosesList
        .filter((item: any) => item.activity)
        .map((item: any) => ({ id: item.ID, quantity: item.quantity }));

    const environment = process.env.REACT_APP_ENVIRONMENT;
    const guestAccountId = environment === "production" ? "120" : "70"; //troll konto
    
    const clientData = () => {
        if (isLogged) {
            return {
                companyData: userData.companyData,
                individualData: userData.individualData,
                id: userData.id,
                email: userData.mail,
                phone: userData.phone,
            }
        } else {
            return {
                companyData: tempAddress?.fname + " " + tempAddress?.lname,
                individualData: tempAddress,
                id: guestAccountId, //tu podac id troll konta
                email: tempAddress.mail,
                phone: tempAddress.phone,
            }
        }
    }
    const OrderData: OrderDataTypes = {
        clientData: JSON.stringify(clientData()),
        clientId: isLogged ? userData?.id : guestAccountId, //tu podac id troll konta
        courierInfo: courierInfo,
        comment: comment,
        hosesIds: JSON.stringify(listOfHosesIdsWithQuantity),
        invoiceData: JSON.stringify(invoiceData),
        payment: payment ? payment : "payu",
        price: cartTotalPrice,
        sendData: JSON.stringify(tempAddress),
        shipping: shippingIncluded ? 0 : 2000,
    };

    return OrderData;
};
