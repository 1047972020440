import {
    ACCOUNT_REGISTER,
    ADD_ORDER,
    GET_USER_DATA,
    REMOVE_HOSE_BY_ID,
    REMOVE_USER_DATA,
    SET_CART_ITEM_ACTIVITY,
    SET_CART_ITEM_QUANTITY,
    SET_COMMENT,
    SET_COURIER_INFO,
    SET_INVOICE_DATA,
    SET_PHONE_NUMBER_FOR_SMS_VERIFICATION,
    SET_TEMP_ADDRESS,
    UPDATE_HOSES_LIST,
} from "../constants/actionNames";
import { jsonParse } from "../utils/jsonParse";
import { countSingleHosePrice } from "../utils/price/countSingleHosePrice";

const INITIAL_STATE = {
    register: "",
    isLogged: false,
    loading: false,
    smsLogInPhone: "",
    user: {
        userData: [],
        hosesIds: [],
        hosesList: [],
        orders: [],
        updatedCartHoses: [],
    },
    addOrder: null,
    tempAddress: {
        fname: "",
        lname: "",
        address1: "",
        address2: "",
        mail: "",
        phone: "",
    },
    invoiceData: {
        address1: "",
        address2: "",
        companyName: "",
        nip: "",
    },
    courierInfo: "",
    comment: "",
};
export const accountReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ACCOUNT_REGISTER + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case ACCOUNT_REGISTER + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case ACCOUNT_REGISTER + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                register: setRegisterState(action.payload.data),
            };
        case GET_USER_DATA + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case GET_USER_DATA + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case GET_USER_DATA + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                user: convertHosesList(state, action.payload.data),
                isLogged: true,
            };
        case REMOVE_USER_DATA:
            return state = INITIAL_STATE;
        case SET_CART_ITEM_ACTIVITY:
            return { ...state, user: filterCartItemsAndSetActivity(state, action.payload) };
        case SET_CART_ITEM_QUANTITY:
            return { ...state, user: setCartItemQuantity(state, action.payload) };
        case ADD_ORDER + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case ADD_ORDER + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case ADD_ORDER + "_FULFILLED":
            return { ...state, loading: false, error: false, fetched: true, addOrder: action.payload.data };
        case REMOVE_HOSE_BY_ID + "_PENDING":
            return { ...state, loading: true, error: false };
        case REMOVE_HOSE_BY_ID + "_REJECTED":
            return { ...state, loading: false, error: true };
        case REMOVE_HOSE_BY_ID + "_FULFILLED":
            return { ...state, loading: false, error: false };
        case SET_TEMP_ADDRESS:
            return { ...state, tempAddress: action.payload };
        case SET_INVOICE_DATA:
            return { ...state, invoiceData: action.payload };
        case SET_COURIER_INFO:
            return { ...state, courierInfo: action.payload };
        case SET_COMMENT:
            return { ...state, comment: action.payload };
        case SET_PHONE_NUMBER_FOR_SMS_VERIFICATION:
            return { ...state, smsLogInPhone: action.payload };
        case UPDATE_HOSES_LIST + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case UPDATE_HOSES_LIST + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case UPDATE_HOSES_LIST + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                user: {
                    ...state.user,
                    updatedCartHoses: updateHosesListWithNewData(state?.user?.hosesList, action.payload.data),
                },
            };
        default:
            return state;
    }
};
const updateHosesListWithNewData = (hosesList, payload) => {
    // Przekształć payload w mapę, gdzie kluczem jest symbol
    const payloadMap = new Map();
    payload.forEach((item) => {
        const symbol = Object.keys(item)[0];
        payloadMap.set(symbol, item[symbol]);
    });

    // Aktualizuj hosesList na podstawie payloadMap
    return hosesList.map((hose) => {
        ["leftFittingDetails", "rightFittingDetails", "sleeveDetails", "hoseDetails"].forEach((detailType) => {
            const symbol = hose[detailType].symbol;
            if (payloadMap.has(symbol)) {
                hose[detailType].cena = payloadMap.get(symbol).cena_brutto;
                hose[detailType].stan_magazynowy = payloadMap.get(symbol).stan_magazynowy;
            }
        });
        hose.totalPrice = countSingleHosePrice({
            length: hose.length,
            hosePrice: hose?.hoseDetails?.cena,
            leftFittingPrice: hose?.leftFittingDetails?.cena,
            rightFittingPrice: hose?.rightFittingDetails?.cena,
            sleevePrice: hose?.sleeveDetails?.cena,
            coverPrice: hose?.coverDetails?.cena,
            coverLength: hose?.coverDetails?.length,
        });
        return hose;
    });
};

const setCartItemQuantity = (state: any, payload: any) => {
    const updatedHosesList = state?.user?.updatedCartHoses?.map((item: any) => {
        if (item.ID === payload.hoseId) {
            item.quantity = payload.quantity;
        }
        return item;
    });
    return { ...state.user, updatedCartHoses: updatedHosesList };
};
const filterCartItemsAndSetActivity = (state: any, payload: any) => {
    const updatedHosesList = state?.user?.updatedCartHoses?.map((item: any) => {
        if (item.ID === payload.hoseId) {
            item.activity = payload.state;
        }
        return item;
    });
    return { ...state.user, updatedCartHoses: updatedHosesList };
};
const convertHosesList = (state: any, payload: any) => {
    // pierwszy obiekt to dane usera
    // ostatni obiekt to lista orderów
    // obiekty pomiędzy to węze w koszyku
    const userData = payload[0];
    const hosesIds = userData?.hosesInCart;

    let ordersList = null;
    let hosesList = [];

    const processHoseItem = (item: any) => {
        const singleHosePriceSum = countSingleHosePrice({
            length: item.length,
            hosePrice: item?.hoseDetails?.cena,
            leftFittingPrice: item?.leftFittingDetails?.cena,
            rightFittingPrice: item?.rightFittingDetails?.cena,
            sleevePrice: item?.sleeveDetails?.cena,
            coverPrice: item?.coverDetails?.cena,
            coverLength: item?.coverDetails?.length,
        });
        return { ...item, totalPrice: singleHosePriceSum, activity: true, quantity: 1 };
    };

    for (let i = 1; i < payload.length; i++) {
        const item = payload[i];

        if (Array.isArray(item)) {
            ordersList = item;
            break;
        } else {
            hosesList.push(processHoseItem(item));
        }
    }

    return {
        userData: userData,
        hosesIds: hosesIds,
        hosesList: hosesList,
        orders: ordersList ? stringifyOrdersList(ordersList) : [],
        updatedCartHoses: state.user.updatedCartHoses.length > 0 ? state.user.updatedCartHoses : [],
    };
};

const stringifyOrdersList = (orders: any) => {
    const objectFromArray = orders?.map((item: any) => {
        return { userData: item[0], userEmail: item[1], hoses: item[2] };
    });
    const parseStringifiedData = objectFromArray.map((item: any) => {
        return {
            ...item,
            userData: {
                ...item.userData,
                invoiceData: jsonParse(item?.userData?.invoiceData),
                sendData: jsonParse(item?.userData?.sendData),
                clientData: jsonParse(item?.userData?.clientData),
            },
        };
    });
    return parseStringifiedData;
};
const setRegisterState = (payload: any) => {
    if (payload.length > 8) {
        return "Wystąpił błąd";
    }
    return payload;
};
