import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconPosition0Svg from "../../icons/positions/Icon_Position0Svg";
import IconPosition135Svg from "../../icons/positions/Icon_Position135Svg";
import IconPosition180Svg from "../../icons/positions/Icon_Position180Svg";
import IconPosition225Svg from "../../icons/positions/Icon_Position225Svg";
import IconPosition270Svg from "../../icons/positions/Icon_Position270Svg";
import IconPosition315Svg from "../../icons/positions/Icon_Position315Svg";
import IconPosition45Svg from "../../icons/positions/Icon_Position45Svg";
import IconPosition90Svg from "../../icons/positions/Icon_Position90Svg";
import { TitleHeader } from "../../styled/typography";
import { Button } from "../buttons/Button";
import AppToolTip from "../tooltips/AppToolTip";

const angles = ["90 stopni", "45 stopni"];

const FittingsPosition = ({
    currentPosition,
    setCurrentPosition,
    leftFittingAngle,
    leftFittingType,
    rightFittingAngle,
    rightFittingType,
    isEdited = false,
}) => {
    const [hoverPosition, setHoverPosition] = useState(currentPosition);

    const availablePositions = [0, 45, 90, 135, 180, 225, 270, 315];
    const iconSize = 160;
    const switchPosition = (position: string | number) => {
        const pos = typeof position === "string" ? (position = parseInt(position)) : position;
        switch (pos) {
            case 0:
                return <IconPosition0Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 45:
                return <IconPosition45Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 90:
                return <IconPosition90Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 135:
                return <IconPosition135Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 180:
                return <IconPosition180Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 225:
                return <IconPosition225Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 270:
                return <IconPosition270Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 315:
                return <IconPosition315Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            default:
                return <IconPosition0Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
        }
    };

    const btnHover = (item: any) => {
        setHoverPosition(item);
    };

    const checkFittingCondition = (
        leftFittingAngle: string,
        rightFittingAngle: string,
        leftFittingType: string,
        rightFittingType: string
    ): boolean => {
        const isAngle = (angle: string) => angles.includes(angle);
        const isEyelet = (type: string) => type === "oczko";

        return (
            (isAngle(leftFittingAngle) && isAngle(rightFittingAngle)) ||
            (isEyelet(leftFittingType) && isEyelet(rightFittingType)) ||
            (isAngle(leftFittingAngle) && isEyelet(rightFittingType)) ||
            (isAngle(rightFittingAngle) && isEyelet(leftFittingType))
        );
    };
    const condition = checkFittingCondition(leftFittingAngle, rightFittingAngle, leftFittingType, rightFittingType);
    if (!condition) {
        return null;
    }
    return (
        <Wrapper>
            <TitleHeader style={{ fontSize: 18, marginBottom: 10 }}>Orientacja zakończeń</TitleHeader>
            <Text>Oba zakucia są kątowe - możesz wybrać ich orientację względem siebie</Text>
            {isEdited && (
                <AppToolTip
                    header="Dlaczego zmiana orientacji zakończeń zmieni kod QR?"
                    text={
                        <p>
                            Wpisując kod z istniejącego przewodu, dodałeś go do koszyka z identycznymi parametrami.
                            Zmiana orientacji zakończeń to modyfikacja istotnego parametru, więc kod QR musi zostać
                            zaktualizowany, aby odzwierciedlał nowe dane przewodu.
                        </p>
                    }>
                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: 10,
                        }}>
                        <Text style={{ color: theme.color.app_red }}>Zmiana długości skutkuje zmianą QR kodu</Text>
                        <InfoIcon />
                    </div>
                </AppToolTip>
            )}
            <Content>
                <ImageWrap>{switchPosition(hoverPosition)}</ImageWrap>
                <ButtonsWrap>
                    {availablePositions.map((item: any, index: number) => (
                        <SingleBtn
                            key={index}
                            onMouseEnter={() => btnHover(item)}
                            onMouseLeave={() => setHoverPosition(currentPosition)}>
                            <Button
                                bgColor={
                                    parseInt(currentPosition) === parseInt(item)
                                        ? theme.color.app_blue2
                                        : theme.color.app_lightBlue
                                }
                                color={
                                    parseInt(currentPosition) === parseInt(item)
                                        ? theme.color.app_lightBlue
                                        : theme.color.app_blue2
                                }
                                title={item + "°"}
                                onClick={() => setCurrentPosition(item)}
                                isButtonGroup
                            />
                        </SingleBtn>
                    ))}
                </ButtonsWrap>
            </Content>
        </Wrapper>
    );
};

export default FittingsPosition;

const Text = styled.p`
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const SingleBtn = styled.div`
    width: 22%;
    padding-bottom: 5px;
    cursor: pointer;
    @media (min-width: 1279px) {
        width: 45%;
    }
`;
const ButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: 1279px) {
        flex-direction: row;
        width: 100%;
        margin-left: 15px;
    }
`;
const ImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
    height: 140px;
    width: 100%;
    padding-top: 7px;
    flex-shrink: 0;
    overflow: hidden;
    margin-bottom: 11px;
    @media (min-width: 1279px) {
        width: 300px;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 1279px) {
        flex-direction: row;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const InfoIcon = styled(QuestionCircleOutlined)`
    font-size: 16px;
    color: ${({ theme }) => theme.color.app_red};
    margin-left: 3px;
    margin-bottom: 1px;
    @media (max-width: 585px) {
        font-size: 12px;
        margin-left: 1px;
        margin-top: -6px;
    }
`;
