import React from 'react';
import styled from 'styled-components';

export const SummaryElement = ({header, title, style}: any) => {
  return (
      <BottomWrapElement style={style}>
          <TextInfo>{header}</TextInfo>
          <InfoElementValue>{title}</InfoElementValue>
      </BottomWrapElement>
  );
};

const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 30px;
    margin: 3px 0px;
    padding: 3px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_lightBlue};
`;
const TextInfo = styled.p`
    margin: 0 0 3px 0;
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_blue2};
`;
const InfoElementValue = styled.p`
    margin: 0;
    display: block;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
