import axios from "../utils/axiosInterceptor";

export interface addOrderTypes {
    clientId: any;
    clientData: any;
    courierInfo: any;
    invoiceData: any;
    price: any;
    payment: any;
    shipping: any;
    sendData: any;
    hosesIds: any;
    comment?: any;
}

export const getOrderByIdService = (id: any) => {
    return axios.get(`get-order.php?id=${id}`);
};

export const addOrderService = ({
    clientId,
    clientData,
    courierInfo,
    invoiceData,
    price,
    payment,
    shipping,
    sendData,
    hosesIds,
    comment,
}: addOrderTypes) => {
    return axios.post(
        `order.php`,
        `clientId=${clientId}
        &clientData=${clientData}
        &courierInfo=${courierInfo}
        &invoiceData=${invoiceData}
        &price=${price}
        &payment=${payment}
        &shipping=${shipping}
        &sendData=${sendData}
        &hosesIds=${hosesIds}
        &comment=${comment}`
    );
};

export const addGuestOrderService = async (
    {
        clientId,
        clientData,
        courierInfo,
        invoiceData,
        price,
        payment,
        shipping,
        sendData,
        hosesIds,
        comment,
    }: addOrderTypes,
    xApiKey
) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const apiToken = process.env.REACT_APP_MY_API_KEY;
    const url = environment === "production" ? "https://api.zakuwanie24.pl/order.php" : "https://api-test.zakuwanie24.pl/order.php";
    axios.defaults.headers.common["api-token"] = apiToken;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "x-api-key": xApiKey,
                "api-token": apiToken,
            },
            body: `clientId=${clientId}&clientData=${clientData}&courierInfo=${courierInfo}&invoiceData=${invoiceData}&price=${price}&payment=${payment}&shipping=${shipping}&sendData=${sendData}&hosesIds=${hosesIds}&comment=${comment}&oldClient=${0}`,
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Response body:", errorText);
            throw new Error(`Request failed with status ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("An error occurred: ", error);
    }
};
export const getXAndContinueCreatingOrder = async (orderData) => {
    const username = "sklep";
    const password = "Secret00!2024";
    try {
        const response = await fetch(
            "https://panel-zakuwanie24-staging.netlify.app/.netlify/functions/password-verification",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            }
        );
        const data = await response.json();
        if (data?.xApiKey) {
            return addGuestOrderService(orderData, data?.xApiKey);
        } else {
            return alert("Wystąpił błąd. Spróbuj ponownie.");
        }
    } catch (error) {
        console.error(error);
    }
};
