import {
    CURRENT_HOSE,
    CURRENT_HOSE_ADDITIONAL_LABEL_TEXT,
    CURRENT_HOSE_ADDITIONAL_NOTES,
    CURRENT_HOSE_CATEGORY,
    CURRENT_HOSE_COVER,
    CURRENT_HOSE_COVER_LENGTH,
    CURRENT_HOSE_COVER_POSITION,
    CURRENT_HOSE_DIMENSION,
    CURRENT_HOSE_LENGTH,
    CURRENT_HOSE_ORIENTATION,
    CURRENT_RESET,
    LEFT_FITTING_ANGLE,
    LEFT_FITTING_DIAMETER,
    LEFT_FITTING_PARAMETERS,
    LEFT_FITTING_TYPE,
    RIGHT_FITTING_DIAMETER,
    RIGHT_FITTING_PARAMETERS,
    RIGTH_FITTING_Angle,
    RIGTH_FITTING_TYPE,
    CURRENT_HOSE_COVER_CLEAN
} from "../constants/actionNames";

const INITIAL_STATE = {
    hose: {
        category: null,
        dimension: null,
        parameters: null,
        length: null,
        orientation: 0,
    },
    leftFitting: {
        type: null,
        angle: null,
        diameter: null,
        parameters: null,
    },
    rightFitting: {
        type: null,
        angle: null,
        diameter: null,
        parameters: null,
    },
    cover: {
        coverData: null,
        coverLength: null,
        coverPercentageLength: null,
        coverPosition: "LEFT",
    },
    additionalData: {
        additionalLabelText: null,
        previousNotes: null,
        additionalNotes: {
            createdAt: null,
            description: null,
        },
    },
};
export const currentHoseReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case CURRENT_HOSE_COVER_CLEAN:
            return {
                ...state,
                cover: {...INITIAL_STATE.cover},
            };
        case CURRENT_HOSE_COVER:
            return {
                ...state,
                cover: { ...state.cover, coverData: action.payload },
            };
        case CURRENT_HOSE_COVER_LENGTH:
            return {
                ...state,
                cover: {
                    ...state.cover,
                    coverLength: action.payload.length,
                    coverPercentageLength: action.payload.percentageLength,
                },
            };
        case CURRENT_HOSE_COVER_POSITION:
            return {
                ...state,
                cover: { ...state.cover, coverPosition: action.payload },
            };
        case CURRENT_HOSE_CATEGORY:
            return {
                ...state,
                hose: { ...state.hose, category: action.payload },
            };
        case CURRENT_HOSE_DIMENSION:
            return { ...state, hose: { ...state.hose, dimension: action.payload } };
        case CURRENT_HOSE:
            return { ...state, hose: { ...state.hose, parameters: action.payload } };
        case CURRENT_HOSE_LENGTH:
            return { ...state, hose: { ...state.hose, length: action.payload } };
        case CURRENT_HOSE_ADDITIONAL_LABEL_TEXT:
            return { ...state, additionalData: { ...state.additionalData, additionalLabelText: action.payload } };
        case CURRENT_HOSE_ADDITIONAL_NOTES:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    additionalNotes: {
                        description: action.payload?.description,
                        createdAt: action.payload?.createdAt
                    },
                },
            };
        case CURRENT_HOSE_ORIENTATION:
            return { ...state, hose: { ...state.hose, orientation: action.payload } };
        case LEFT_FITTING_TYPE:
            return {
                ...state,
                leftFitting: { ...state.leftFitting, type: action.payload },
            };
        case RIGTH_FITTING_TYPE:
            return {
                ...state,
                rightFitting: { ...state.rightFitting, type: action.payload },
            };
        case LEFT_FITTING_ANGLE:
            return {
                ...state,
                leftFitting: { ...state.leftFitting, angle: action.payload },
            };
        case RIGTH_FITTING_Angle:
            return {
                ...state,
                rightFitting: { ...state.rightFitting, angle: action.payload },
            };
        case LEFT_FITTING_DIAMETER:
            return {
                ...state,
                leftFitting: { ...state.leftFitting, diameter: action.payload },
            };
        case RIGHT_FITTING_DIAMETER:
            return {
                ...state,
                rightFitting: { ...state.rightFitting, diameter: action.payload },
            };
        case LEFT_FITTING_PARAMETERS:
            return {
                ...state,
                leftFitting: { ...state.leftFitting, parameters: action.payload },
            };
        case RIGHT_FITTING_PARAMETERS:
            return {
                ...state,
                rightFitting: { ...state.rightFitting, parameters: action.payload },
            };

        case CURRENT_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
};
