import { useState } from "react";
import AuthCode from "react-auth-code-input";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getHoseByIdAction } from "../../actions/addHoseToCart.action";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import "./QrCodeInput.styles.css";
import przewodZPlakietka from "../../assets/przewodZPlakietka.png";

export const QrCodeWrite = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [qrCode, onQrCodeChange] = useState(null);
    const [error, setError] = useState(false);

    const goToHoseDetails = () => {
        dispatch(getHoseByIdAction({ qrCode })).then((r) => {
            //TODO sprawdz czy ten ostatni warunek nie psuje produkcji
            if (r?.value?.data?.ID === null || typeof r?.action?.payload === "string" || typeof r?.action?.payload?.data === "string") {
                setError(true);
                return;
            } else {
                const hoseData = r?.value?.data;
                navigate(`/Modal/HoseDetails`, {
                    state: {
                        backgroundLocation: location,
                        hoseDetailsData: hoseData,
                        showOrderbutton: true,
                    },
                });
            }
        });
    };
    return (
        <Wrapper>
            <Image src={przewodZPlakietka} alt="qr-code" />
            <Header>
                Masz przewód z naszą etykietą QR? Podaj kod, aby zobaczyć jego parametry i skorzystać z opcji szybkiego
                zakupu.
            </Header>

            <AuthCode
                autoFocus={false}
                containerClassName="qrCodeContainer"
                inputClassName="qrCodeInput"
                onChange={(e: any) => {
                    setError(false);
                    onQrCodeChange(e);
                }}
            />
            {error && (
                <Error>
                    Oops! Podany kod nie odpowiada żadnemu z naszych produktów. Jeśli uważasz, że to błąd, napisz do nas
                    na info@zakuwanie24.pl lub zadzwoń pod numer 603 304 530.
                </Error>
            )}
            <AppButtonRegular title="Sprawdź teraz" onClick={goToHoseDetails} />
        </Wrapper>
    );
};

const Image = styled.img`
    width: 106%;
`;
const Error = styled.p`
    color: ${({ theme }) => theme.color.app_red};
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Header = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    width: 100%;
`;
