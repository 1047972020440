import { ShoppingCartOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import OpenPdfButton from "../../pdf/OpenPdfButton";
import { AppButtonText } from "../buttons/App_ButtonText";

export const OrderHoseDetailsAction = ({ hoseData, orderSameHose }) => {
    return (
        <Wrapper>
            <BottomWrapElement>
                <TextInfo>Ilość przewodów</TextInfo>
                <TextInfo style={{ color: theme.color.black }}>{hoseData?.quantity} szt.</TextInfo>
            </BottomWrapElement>
            <BottomWrapElement>
                <OpenPdfButton hoseData={hoseData} />
            </BottomWrapElement>
            <BottomWrapElement>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <ShoppingCartOutlined style={{ color: theme.color.app_blue2, marginRight: 5, fontSize: 18 }} />
                    <AppButtonText title={`Dodaj taki sam przewód do koszyka`} onClick={orderSameHose} />
                </div>
            </BottomWrapElement>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    border-radius: 5px;
    margin-bottom: 3px;
`;
const TextInfo = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.app_blue2};
`;
const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 0px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_lightBlue};
    @media (max-width: 585px) {
        padding: 0px 8px;
    }
`;
