import { object, string } from "yup";
export interface Address {
    nip: string;
    companyName: string;
    address1: string;
    address2: string;
}

export const schemaInvoiceData = object({
    address1: string()
        .required("Ulica i numer budynku dla oferty / proformy są wymagane")
        .min(2, "Ulica i numer dla oferty / proformy muszą zawierać co najmniej 2 znaki"),
    address2: string()
        .required("Kod pocztowy i miejscowość dla oferty / proformy są wymagane")
        .min(2, "Kod pocztowy i miejscowość dla oferty / proformy muszą zawierać co najmniej 2 znaki"),
    companyName: string().required("Nazwa firmy dla oferty / proformy jest wymagana").min(2, "Nazwa firmy dla oferty / proformy musi zawierać co najmniej 2 znaki"),
    nip: string().required("NIP dla oferty / proformy jest wymagany").min(2, "NIP dla oferty / proformy jest wymagany"),
});

export const validateCartInvoiceData = async (invoiceData: Address) => {
    try {
        const isValid = await schemaInvoiceData.validate(invoiceData);
        if (isValid) {
            return true;
        }
    } catch (err: any) {
        return err.message;
    }
};

