import { useState } from "react";
import styled from "styled-components";
import { Text } from "../../styled/typography";
import { ListItemCover, ListItemCoverEmpty } from "../listElements/ListItem_Cover";
import CoverSelectModal from "../modals/CoverSelect_Modal";

export const HoseCover = ({ coverData, clearCover, setCurrentCover, coversList, isAnyCoverPicked }: any) => {
    const [modalOpen, setModalOpen] = useState({ data: null, state: false });
    return (
        <Wrapper>
            <Text style={{ marginBottom: 10 }}>
                Możesz wybrać dodatkową osłonę chroniącą przed przetarciem lub wysoką temperaturą
            </Text>
            <ListWrapper>
                <ListItemCoverEmpty isSelected={!isAnyCoverPicked} onSelect={clearCover} />
                {coversList?.map((item: any, index) => (
                    <ListItemCover
                        isSelected={
                            (coverData?.symbol?.toLowerCase() || coverData?.Symbol?.toLowerCase()) ===
                            (item?.symbol?.toLowerCase() || item?.Symbol?.toLowerCase())
                        }
                        onSelect={() => setCurrentCover({ ...item, symbol: item?.symbol })}
                        key={index}
                        data={item}
                        openModal={({ data, state }) => setModalOpen({ data, state })}
                    />
                ))}
            </ListWrapper>
            <CoverSelectModal
                data={modalOpen.data}
                isOpen={modalOpen.state}
                setOpen={() => setModalOpen({ data: modalOpen?.data, state: !modalOpen.state })}
            />
        </Wrapper>
    );
};
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-top: 26px;
`;
