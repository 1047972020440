import { CheckCircleOutlined, ContainerOutlined, FileTextOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getOrderByIdAction } from "../actions/orders.action";
import { AppButtonBorderWithIcon } from "../components/buttons/App_ButtonBorderWithIcon";
import { AppButtonRegular } from "../components/buttons/App_ButtonRegular";
import { ReusableInfoElementWithEmoji } from "../components/elements/ReusableInfoElementWithEmoji";
import { theme } from "../constants/Theme";
import { useAppDispatch } from "../hooks/useAppDispatch/useAppDispatch";
import { createAndOpenOfferPdf } from "../pdf/createAndOpenOfferPdf";
import { RootState } from "../reducers";
import { Header } from "../styled/typography";
import { decryptOrderId } from "../utils/crypto/crypto";

export const OrderCreatedWithoutPaymentRoute = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const { order } = useSelector((state: RootState) => state.orders);
    const cleanOrderId = orderId.split("_")[0];
    const decryptedOrderId = decryptOrderId(cleanOrderId);
    const paymentType = order?.[0]?.payment;
    const hosesInOrder = order?.[2];

    useEffect(() => {
        dispatch(getOrderByIdAction(decryptedOrderId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const navigateToSingleOrder = () => {
        const urlId = encodeURIComponent(orderId);
        navigate(`/single-order/${urlId}`);
    };
    const displayDocsOnPaymentType = () => {
        const trimmedInput = paymentType?.trim().toLowerCase();
        if (trimmedInput === "payu") {
            return null;
        } else {
            return (
                <>
                    <AppButtonBorderWithIcon
                        description={`Wyświetl fakturę proforma`}
                        style={{
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            backgroundColor: theme.color.app_lightBlue,
                            marginTop: 16,
                            marginBottom: 4,
                        }}
                        textStyle={{ fontWeight: 600, textTransform: "uppercase" }}
                        icon={
                            <ContainerOutlined style={{ color: theme.color.app_blue2, marginRight: 8, fontSize: 18 }} />
                        }
                        onClick={() =>
                            createAndOpenOfferPdf({
                                hoses: hosesInOrder,
                                orderData: order[0],
                                paymentType: "proforma",
                            })
                        }
                    />
                    <AppButtonBorderWithIcon
                        description={`Wyświetl ofertę`}
                        style={{
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            backgroundColor: theme.color.app_lightBlue,
                            marginTop: 10,
                            marginBottom: 4,
                        }}
                        textStyle={{ fontWeight: 600, textTransform: "uppercase" }}
                        icon={
                            <FileTextOutlined style={{ color: theme.color.app_blue2, marginRight: 8, fontSize: 18 }}/>
                        }
                        onClick={() =>
                            createAndOpenOfferPdf({
                                hoses: hosesInOrder,
                                orderData: order[0],
                                paymentType: "offer",
                            })
                        }
                    />
                    <AppButtonRegular style={{ marginTop: 50 }} onClick={navigateToSingleOrder} title={"Dalej"} />
                </>
            );
        }
    };

    return (
        <Wrapper>
            <Content>
                <HeaderCustom>
                    <CheckCircleOutlined style={{ fontSize: 28, marginRight: 10 }} />
                    Twoje zamówienie nr {decryptedOrderId} zostało utworzone
                </HeaderCustom>

                <ReusableInfoElementWithEmoji
                    style={{ backgroundColor: "white", marginTop: 20, marginBottom: 20 }}
                    infoRowStyle={{ alignItems: "center" }}
                    infos={[
                        {
                            emoji: "📦",
                            description: `Zamówienie nr ${decryptedOrderId} zostało utworzone, poniej znajdziesz dokument do pobrania`,
                        },
                        {
                            emoji: "📞",
                            description: `Jeśli napotkałeś problem zadzwoń pod +48 885 443 185 i podaj numer zamówienia`,
                        },
                        {
                            emoji: "",
                            description: displayDocsOnPaymentType(),
                        },
                    ]}
                />
            </Content>
        </Wrapper>
    );
};

const HeaderCustom = styled(Header)`
    display: flex;
    align-items: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
`;
const Wrapper = styled.div`
    border-radius: 3px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 20px;
    padding: 50px 20px;
    max-width: 500px;
`;
