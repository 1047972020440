import axios from "../utils/axiosInterceptor";
import { coverObjectStringify } from "../utils/coverObjectStringify";

export interface addHoseToCartServiceTypes {
    hoseSymbol: string;
    leftFittingSymbol: string;
    rightFittingSymbol: string;
    sleeveSymbol: string;
    length: string;
    ending: string;
    clientId: number;
    cover?: string;
    additionalLabel?: string;
    additionalNotes?: string;
}
export interface getHoseByIdTypes {
    qrCode: string | number;
}
export interface changeHosesUserByIdTypes {
    userId: string;
    hoseId: string;
}

export const addHoseToCartService = async ({
    hoseSymbol,
    leftFittingSymbol,
    rightFittingSymbol,
    sleeveSymbol,
    length,
    ending,
    clientId,
    cover,
    additionalLabel,
    additionalNotes,
}: addHoseToCartServiceTypes) => {
    const coverParam = cover ? `&cover=${cover}` : "";
    const additionalLabelParam = additionalLabel ? `&additionalLabel=${additionalLabel}` : "";
    const additionalNotesParem = additionalNotes ? `&additionalNotes=${additionalNotes}` : "";
    return await axios.get(
        `/add-hose.php?hose=${hoseSymbol}&left_fitting=${leftFittingSymbol}&right_fitting=${rightFittingSymbol}&sleeve=${sleeveSymbol}&length=${length}&ending=${ending}&client-id=${clientId}${additionalLabelParam}${additionalNotesParem}${coverParam}`
    );
};
export const addHoseWithSameQRToCartService = async ({ hoseId, userId }: any) => {
    return await axios.get(`add-existing-to-cart.php?userId=${userId}&hoseId=${hoseId}`);
};

export const getHoseByIdService = ({ qrCode }: getHoseByIdTypes) => {
    return axios.get(`/get-hose.php?id=${qrCode}`);
};

export const removeHoseByIdService = ({ clientId, hoseId }: any) => {
    return axios.get(`/remove-hose.php?client-id=${clientId}&hose-id=${hoseId}`);
};

export const changeHosesUserByIdService = ({ userId, hoseId }: changeHosesUserByIdTypes) => {
    return axios.get(`/change-user-for-hose.php?user=${userId}&hoses=${hoseId}`);
};

export const editHoseInCartService = (data: {
    id: string;
    length?: string;
    coverDetails?: string;
    ending?: number | string;
    additionalLabel?: string;
    additionalNotes?: string;
}) => {
    const idParam = data.id ? `id=${data.id}` : "";
    const lengthParam = data.length ? `&length=${data.length}` : "";
    const coverDetailsParam = data.coverDetails ? `&cover=${data.coverDetails}` : "";
    const endingParam = data.ending !== null && data.ending !== undefined ? `&ending=${data.ending}` : "";
    const additionalLabelParam = `&additionalLabel=${data.additionalLabel}`;
    const additionalNotesParam = `&additionalNotes=${coverObjectStringify(data.additionalNotes)}`;
    const url = `/edit-hose-in-cart.php?${idParam}${lengthParam}${coverDetailsParam}${endingParam}${additionalLabelParam}${additionalNotesParam}`;
    return axios.get(url);
};
