import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Checkbox } from "react-input-checkbox";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../constants/Theme";
import { AppButtonText } from "../../buttons/App_ButtonText";
import { CartListItemPriceWithModal } from "./CartListItemPriceWithModal";

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const CartListItemActions = ({
    data,
    changeItemActivity,
    quantity,
    changeItemQuantity,
    totalPrice,
    removeHoseFromCart,
}) => {
    let location = useLocation();

    return (
        <Wrapper>
            <BottomWrapElement>
                <div style={{ display: "flex", flexDirection: "row" }} onClick={removeHoseFromCart}>
                    <DeleteOutlined style={{ color: theme.color.app_red, marginRight: 5 }} />
                    <AppButtonText style={{ color: theme.color.app_red }} title={"Usuń"} onClick={undefined} />
                </div>
                <Link
                    style={{ textDecoration: "none" }}
                    to={`/Modal/EditHoseInCart`}
                    state={{ backgroundLocation: location, hoseDetailsData: data }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <EditOutlined style={{ color: theme.color.app_green, marginRight: 5 }} />
                        <AppButtonText style={{ color: theme.color.app_green }} title={"Edytuj"} onClick={undefined} />
                    </div>
                </Link>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Uwzględnij w zamówieniu</TextInfo>

                <Checkbox
                    modification="cartItemDisable"
                    theme="fancy-checkbox"
                    disabled={false}
                    value={data.activity}
                    onChange={() => changeItemActivity(!data?.activity, data?.ID)}>
                    <p></p>
                </Checkbox>
            </BottomWrapElement>

            <BottomWrapElement>
                <TextInfo>Zamów w ilości</TextInfo>

                <QuantitySelect
                    style={{ opacity: !data.activity ? 0.4 : 1 }}
                    value={quantity}
                    onChange={(e) => changeItemQuantity(e.target.value, data?.ID)}>
                    {options.map((item) => (
                        <option key={item} value={item}>
                            {item + " szt."}
                        </option>
                    ))}
                </QuantitySelect>
            </BottomWrapElement>

            <CartListItemPriceWithModal data={data} totalPrice={totalPrice} />
        </Wrapper>
    );
};
const Wrapper = styled.div`
    border-radius: 5px;
    margin-bottom: 3px;
`;
const TextInfo = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.app_blue2};
`;
const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 0px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_lightBlue};
    @media (max-width: 585px) {
        padding: 0px 8px;
    }
`;
const QuantitySelect = styled.select`
    width: 75px;
    height: 28px;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
