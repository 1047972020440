import styled from "styled-components";
import { theme } from "../../../constants/Theme";

export const MainParameters = ({ data, coverDetails }: any) => {
    return (
        <Wrapper style={{ backgroundColor: data.activity ? theme.color.app_lightBlue : theme.color.app_grey4 }}>
            <BottomWrapElement>
                <TextInfo>Przewód</TextInfo>
                <InfoElementValue>{data?.hoseDetails?.nazwa}</InfoElementValue>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Zakucie lewe</TextInfo>
                <InfoElementValue>{data?.leftFittingDetails?.nazwa}</InfoElementValue>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Zakucie prawe</TextInfo>
                <InfoElementValue>{data?.rightFittingDetails?.nazwa}</InfoElementValue>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Długość</TextInfo>
                <InfoElementValue>{data?.length + " cm"}</InfoElementValue>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Orientacja zakończeń</TextInfo>
                <InfoElementValue>{data?.ending} stopni</InfoElementValue>
            </BottomWrapElement>

            <BottomWrapElement>
                <TextInfo>Osłona przewodu</TextInfo>
                <InfoElementValue>
                    {coverDetails?.length ? coverDetails?.nazwa + " | " + coverDetails?.percentageLength * 100 + " %" : "- Brak"}
                </InfoElementValue>
            </BottomWrapElement>
            <BottomWrapElement>
                <TextInfo>Plakietka informacyjna</TextInfo>
                <InfoElementValue>{data?.additionalLabel ? data?.additionalLabel : "- Brak"}</InfoElementValue>
            </BottomWrapElement>

            <BottomWrapElement>
                <TextInfo>Komentarz do przewodu</TextInfo>
                <InfoElementValue>
                    {data?.additionalNotes?.description ? data?.additionalNotes?.description : "- Brak"}
                </InfoElementValue>
            </BottomWrapElement>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    box-sizing: border-box;
    flex-direction: column;
    width: 50%;
    border-radius: 5px;
    padding: 12px 0px 12px 12px;
    @media (max-width: 585px) {
        padding: 6px 0px 6px 6px;
    }
`;
const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 30px;
    margin: 3px 0px;
    padding: 3px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_lightBlue};
`;
const TextInfo = styled.p`
    margin: 0 0 3px 0;
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_blue2};
`;
const InfoElementValue = styled.p`
    margin: 0;
    display: block;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
