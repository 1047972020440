import { useSelector } from "react-redux";
import { HoseCover } from "./HoseCover";
import { useEffect, useState } from "react";
import { fetchCoversAction } from "../../actions/cover.action";
import { setCurrentCoverAction, setCurrentCoverLengthAction, setCurrentCoverPositionAction } from "../../actions/currentHose.action";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { HoseCoverProperties } from "./HoseCoverProperties";
import Collapsible from "react-collapsible";
import { theme } from "../../constants/Theme";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { SubHeader } from "../../styled/typography";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import styled from "styled-components";

export const HoseCoverHOC = () => {
    const hoseSymbol = useSelector((state: any) => state.currentHose.hose.parameters.symbol);
    const dispatch = useAppDispatch();
    const coversList = useSelector((state: RootState) => state.cover.covers);
    const { coverData, coverLength, coverPosition } = useSelector((state: RootState) => state.currentHose.cover);
    const currentHose = useSelector((state: any) => state.currentHose);
    const hoseLength = useSelector((state: any) => state.currentHose?.hose?.length);
    const [isAnyCoverPicked, setIsAnyCoverPicked] = useState(false);
    const setCurrentCover = (selectedCover) => {
        let coverLength = currentHose?.cover?.coverLength ?? currentHose?.hose?.length;
        let percentageLength = currentHose?.cover?.percentageLength ?? 1;
        dispatch(setCurrentCoverAction(selectedCover));
        dispatch(setCurrentCoverLengthAction({ length: coverLength, percentageLength: percentageLength }));
        setIsAnyCoverPicked(true);
    };

    const clearCover = () => {
        dispatch(setCurrentCoverAction(null));
        dispatch(setCurrentCoverLengthAction({ length: null, percentageLength: null }));
        dispatch(setCurrentCoverPositionAction(null));
        setIsAnyCoverPicked(false);
    };

    useEffect(() => dispatch(fetchCoversAction(hoseSymbol)), []); // eslint-disable-line react-hooks/exhaustive-deps
    const [collapseOpen, setCollapseOpen] = useState(true);
    const [currentCoverLength, setCurrentCoverLength] = useState(coverLength || hoseLength);

    const setCoverLength = (percentage: number) => {
        setCurrentCoverLength(Math.ceil(hoseLength * percentage));
        dispatch(
            setCurrentCoverLengthAction({
                length: Math.ceil(hoseLength * percentage),
                percentageLength: percentage,
            })
        );
    };
    const setPositionOfCover = (position: any) => dispatch(setCurrentCoverPositionAction(position));
    useEffect(() => {
        setCurrentCoverLength(hoseLength);
    }, [hoseLength]);
    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 10,
                marginBottom: -2,
                paddingBottom: 0,
                backgroundColor: theme.color.white,
                borderColor: collapseOpen ? theme.color.app_blue2 : theme.color.app_lightBlue,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <TitleAndImageWrap>
                            <Collapsible
                                open={true}
                                onOpen={() => setCollapseOpen(true)}
                                onClose={() => setCollapseOpen(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}>
                                        <SubHeader>+ Dodaj osłonę przewodu</SubHeader>
                                        <IconWrap
                                            style={{
                                                transform: collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg />
                                        </IconWrap>
                                    </div>
                                }>
                                <div>
                                    <HoseCover
                                        hoseSymbol={hoseSymbol}
                                        coversList={coversList}
                                        coverData={coverData}
                                        setCurrentCover={(item) => setCurrentCover(item)}
                                        clearCover={clearCover}
                                        isAnyCoverPicked={isAnyCoverPicked}
                                    />
                                    {coverData !== null && (
                                        <HoseCoverProperties
                                            hoseLength={hoseLength}
                                            coverLength={currentCoverLength}
                                            coverPosition={coverPosition}
                                            setCoverLength={(e) => setCoverLength(e)}
                                            setPositionOfCover={(e) => setPositionOfCover(e)}
                                        />
                                    )}
                                    {/* {coverData !== null && (
                                        <HoseCoverProperties
                                            hoseLength={hoseLength}
                                            coverLength={coverLength}
                                            coverPosition={coverPosition}
                                        />
                                    )} */}
                                </div>
                            </Collapsible>
                        </TitleAndImageWrap>
                    ),
                },
            ]}
        />
    );
};

const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

