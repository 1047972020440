import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { theme } from "../../constants/Theme";
import { Text, TitleHeader } from "../../styled/typography";
import { HoseImage } from "../hoseImages/HoseImage";
import InputRegular from "../inputs/InputRegular";
import { ListWrapperSubtitle } from "../listWrappers/ListWrapperSubtitle";
import AppToolTip from "../tooltips/AppToolTip";

const HoseLength = ({ leftFitting, rightFitting, length, onLengthChange, isEdited = false }) => {
    const screenSize = useScreenSize();

    return (
        <Wrapper>
            <TitleHeader style={{ fontSize: 18, marginBottom: 10 }}>Długość</TitleHeader>
            <ListWrapperSubtitle
                description="Podaj długość przewodu w centymetrach, od zakucia do zakucia - według podniższego schematu"
                btnText="Pomoc - Jak poprawnie zmierzyc długość przewodu?"
                url={"https://zakuwanie24.pl/jak-poprawnie-zmierzyc-dlugosc-przewodu/"}
            />
            <div style={{ height: 15 }} />
            <HoseImage
                leftFitting={{ angle: leftFitting?.angle, type: leftFitting?.type }}
                rightFitting={{
                    angle: rightFitting?.angle,
                    type: rightFitting?.type,
                }}
                styleWrapper={{ height: screenSize.width > 600 ? 120 : 100 }}
            />
            {isEdited && (
                <AppToolTip
                    header="Dlaczego zmiana długości zmieni kod QR?"
                    text={
                        <p>
                            Wpisując kod z istniejącego przewodu, dodałeś go do koszyka z identycznymi parametrami.
                            Zmiana długości to modyfikacja istotnego parametru, więc kod QR musi zostać zaktualizowany,
                            aby odzwierciedlał nowe dane przewodu.
                        </p>
                    }>
                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: 10,
                        }}>
                        <Text style={{ color: theme.color.app_red }}>Zmiana długości skutkuje zmianą QR kodu</Text>
                        <InfoIcon />
                    </div>
                </AppToolTip>
            )}
            <InputRegular
                onTextChange={(e: any) => onLengthChange(e.target.value)}
                value={length}
                placeholder="Wpisz długość przewodu"
                type={"number"}
                unit="cm"
                wrapperStyles={{ width: 325, alignSelf: "flex-start" }}
                inputStyle={{ height: 40 }}
                onKeyPress={(event) => {
                    const charCode = event.charCode;
                    const decimalPointCharCode = 46;
                    const commaCharCode = 44;

                    if (charCode === decimalPointCharCode || charCode === commaCharCode) {
                        event.preventDefault();
                    }
                }}
            />
        </Wrapper>
    );
};

export default HoseLength;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 28px;
`;
const InfoIcon = styled(QuestionCircleOutlined)`
    font-size: 16px;
    color: ${({ theme }) => theme.color.app_red};
    margin-left: 3px;
    margin-bottom: 1px;
    @media (max-width: 585px) {
        font-size: 12px;
        margin-left: 1px;
        margin-top: -6px;
    }
`;
