import {
    ADD_HOSE_TO_CART,
    ADD_HOSE_WITH_SAME_QR_TO_CART,
    CHANGE_HOSES_USER_BY_ID,
    CLEAR_GUEST_CART,
    EDITE_HOSE_IN_CART,
    GET_HOSE_BY_ID,
    GUEST_CART_HOSES,
    GUEST_CART_HOSE_REMOVE,
    REMOVE_HOSE_BY_ID,
    SET_GUEST_CART_ITEM_ACTIVITY,
    SET_GUEST_CART_ITEM_QUANTITY,
} from "../constants/actionNames";
import { countSingleHosePrice } from "../utils/price/countSingleHosePrice";

const INITIAL_STATE = {
    fetched: false,
    loading: false,
    error: false,
    addedHose: null,
    hoseById: null,
    guestCartHoses: [],
};
export const addHoseToCartReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ADD_HOSE_TO_CART + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case ADD_HOSE_TO_CART + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case ADD_HOSE_TO_CART + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                addedHose: action.payload.data,
            };
        case ADD_HOSE_WITH_SAME_QR_TO_CART + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case ADD_HOSE_WITH_SAME_QR_TO_CART + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case ADD_HOSE_WITH_SAME_QR_TO_CART + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                addedHose: action.payload.data,
            };
        case REMOVE_HOSE_BY_ID + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case REMOVE_HOSE_BY_ID + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case REMOVE_HOSE_BY_ID + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
            };
        case GET_HOSE_BY_ID + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case GET_HOSE_BY_ID + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case GET_HOSE_BY_ID + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                hoseById: action.payload.data,
            };
        case CHANGE_HOSES_USER_BY_ID + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case CHANGE_HOSES_USER_BY_ID + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case CHANGE_HOSES_USER_BY_ID + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                responseData: action.payload.data,
            };
        case EDITE_HOSE_IN_CART + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case EDITE_HOSE_IN_CART + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case EDITE_HOSE_IN_CART + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                responseData: action.payload.data,
            };
        case GUEST_CART_HOSES:
            return {
                ...state,
                guestCartHoses: addHoseToGuestCart(state.guestCartHoses, action.payload),
            };
        case GUEST_CART_HOSE_REMOVE:
            return {
                ...state,
                guestCartHoses: removeHoseFromGuestCart(state.guestCartHoses, action.payload),
            };
        case CLEAR_GUEST_CART:
            return {
                ...state,
                guestCartHoses: [],
            };
        case SET_GUEST_CART_ITEM_QUANTITY:
            return { ...state, guestCartHoses: setGuestCartItemQuantity(state, action.payload) };
        case SET_GUEST_CART_ITEM_ACTIVITY:
            return { ...state, guestCartHoses: filterGuestCartItemsAndSetActivity(state, action.payload) };

        default:
            return state;
    }
};

const removeHoseFromGuestCart = (state: any, payload: any) => {
    const updatedHoses = state.filter((item: any) => item.ID !== payload);
    return updatedHoses;
};
const addHoseToGuestCart = (state: any, payload: any) => {
    const singleHosePriceSum = countSingleHosePrice({
        length: payload.length,
        hosePrice: payload?.hoseDetails?.cena,
        leftFittingPrice: payload?.leftFittingDetails?.cena,
        rightFittingPrice: payload?.rightFittingDetails?.cena,
        sleevePrice: payload?.sleeveDetails?.cena,
        coverPrice: payload?.coverDetails?.cena,
        coverLength: payload?.coverDetails?.length
    });
    const checkIfHoseIsAlreadyInCart = state.some((item: any) => item.ID === payload.ID);

    if (checkIfHoseIsAlreadyInCart) {
        return state;
    }
    return [...state, { ...payload, totalPrice: singleHosePriceSum, activity: true, quantity: 1 }];
};
const setGuestCartItemQuantity = (state: any, payload: any) => {
    const updatedHosesList = state?.guestCartHoses?.map((item: any) => {
        if (item.ID === payload.hoseId) {
            item.quantity = payload.quantity;
        }
        return item;
    });
    return [...updatedHosesList];
};
const filterGuestCartItemsAndSetActivity = (state: any, payload: any) => {
    const updatedHosesList = state?.guestCartHoses?.map((item: any) => {
        if (item.ID === payload.hoseId) {
            item.activity = payload.state;
        }
        return item;
    });
    return [...updatedHosesList];
};
